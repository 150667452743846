import { render, staticRenderFns } from "./DisputePage.vue?vue&type=template&id=39aa909e&scoped=true"
import script from "./DisputePage.vue?vue&type=script&lang=js"
export * from "./DisputePage.vue?vue&type=script&lang=js"
import style0 from "./DisputePage.vue?vue&type=style&index=0&id=39aa909e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39aa909e",
  null
  
)

export default component.exports