<template>
  <div class="container-fluid">
     <p>&nbsp;</p>
      <p class="welcome">Welcome {{ firstname }}</p>
      <div class="search-bar">
        <div class="inputs-panel">
          <input type="text" name="" id="" class="input-field"   placeholder="Search here...">
          <button class="search-button"><img src="../assets/search.svg" width="24px" height="24px"> </button>
        </div>
        <div>
          <!-- <button class="get-virtual-card" > Get Virtual Card</button> -->
        </div>
      </div>
       <div class="col-lg-12 col-sm-12 pt-3">

       </div>
       <div class="col-lg-12 col-sm-12">
        <div class="col-lg-6 col-sm-6 pt-3">
          <div class="font-weight-bold">My Transactions</div>
          <div class="dashboard-inner" v-if="payment_details == ''">
            <!-- <div class="font-weight-bold">My Transactions</div> -->
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            
            <center style="font-size: 16px; color:#24562B"><i class="fa fa-file"></i> No record</center>
          </div>
          <div class="dashboard-inner" v-else>
            <div class="active-c-history" v-for="(data, index) in payment_details" :key="index">
              <div class="activeo">
                <div>
                  <span class="icon2 pl-4"><i class="fa fa-calendar"></i></span>
                </div>
                <div class="active-cc-history">
                  <span class="name">Subscription for: <span>{{data.network}}({{ data.utility }}) - {{ data.mobile_phone}} | {{ data.purchase_item}}</span> </span>
                  <span class="date">Date: {{ new Date(data.created_at).toLocaleString() }}</span>
                </div>
              </div>
              <div class="active-cc-history">
                <span class="amount" v-if="data.amount != null"> &#8358;{{ data.amount.toLocaleString('en-NG', {
                  minimumIntegerDigits: 2
              }) }} </span>
              <span class="amount" v-if="data.amount == null">+ &#8358;{{ '0.00' }} </span>
              </div>
            </div>
          </div>
          <!-- <div class="utilities mt-4">
            <div class="airtime-utility">Airtime and Utility Bills</div>&nbsp;&nbsp;
            <div class="utility-icons">
              <div class="icons" @click="buy_airtime()"><img src="../assets/airtime.svg" alt=""></div>
              <div class="icons" @click="buy_data()"><img src="../assets/data.svg" alt=""></div>
              <div class="icons" @click="buy_electricity()"><img src="../assets/electricity.svg" alt=""></div>
              <div class="icons" @click="buy_cabletv()"><img src="../assets/more.svg" alt=""></div>
            </div>
          </div> -->
        </div>
        <div class="col-lg-6 col-sm-6 pt-3">
          <div class="font-weight-bold">Services</div>
          <div class="dashboard-inner">
            <div class="active-c-user" >
              <div class="activeo">
                <div class="active-cc-user row">
                  <!-- <div class="service-box bg-info" @click="buy_airtime">
                    <i class="fa fa-mobile" style="font-size: 30px"></i>&nbsp; Airtime Topup
                  </div>
                  <div class="service-box bg-success" @click="buy_data()">
                    <i class="fa fa-wifi" style="font-size: 30px"></i>&nbsp; Buy Data
                  </div>

                  <div class="service-box bg-primary" @click="buy_cabletv()">
                    <i class="fas fa-satellite-dish" style="font-size: 30px"></i>&nbsp; DStv Subscription
                  </div>

                  <div class="service-box bg-secondary" @click="buy_cabletv()">
                    <i class="fas fa-satellite-dish" style="font-size: 30px"></i>&nbsp; GOtv Subscription
                  </div> -->

                  <div class="service-box bg-success" @click="buy_electricity()">
                    <i class="fas fa-lightbulb" style="font-size: 30px"></i> &nbsp; Electricity Payment&nbsp; <i class="fa fa-long-arrow-right"></i>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="virtual-card-airtime-money1 mt-4">
            <div class="vcard util-m">
              <img src="../assets/vcard.svg" alt=""><span>Virtual Cards</span>
            </div>
            <div class="airtime-v util-m">
              <img src="../assets/airtime-view.svg" alt=""><span>Airtime - Money</span>
            </div>
          </div> -->
          <!-- <div class="utilities mt-4">
            <div class="airtime-utility">Airtime and Utility Bills</div>&nbsp;&nbsp;
            <div class="utility-icons">
              <div class="icons" @click="buy_airtime()"><img src="../assets/airtime.svg" alt=""></div>
              <div class="icons" @click="buy_data()"><img src="../assets/data.svg" alt=""></div>
              <div class="icons" @click="buy_electricity()"><img src="../assets/electricity.svg" alt=""></div>
              <div class="icons" @click="buy_cabletv()"><img src="../assets/more.svg" alt=""></div>
            </div>
          </div> -->
        </div>
       </div>
       <div v-if="isApprove">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-d">
                                    <div class="inputs ">
                                        <center>
                                            <h4><strong>Confirm Shipping</strong></h4>
                                            <br>
                                            <span style="font-size: 14px; font-family: Poppins">Confirm that you have shipped the item?</span>
                                        </center>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class=" d-flex flex-row justify-content-center">
                                    <button id="submit2" class="btn btn-info" @click="shipped()">Yes I have</button> &nbsp;&nbsp;
                                    <button id="submit2" class="btn btn-secondary" @click="isApprove = false">No not yet</button>
                                </div>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
       </div>

       <div v-if="isApprove1">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-d">
                                    <div class="inputs ">
                                        <center>
                                            <h4><strong>Confirm Receipt</strong></h4>
                                            <br>
                                            <span style="font-size: 14px; font-family: Poppins">Have you really received this item?</span>
                                        </center>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class=" d-flex flex-row justify-content-center">

                                    <button id="submit2" class="btn btn-info" @click="received()"
                                            v-if="!loading2">Yes I have</button> &nbsp;&nbsp;
                                        <button class="btn btn-info" type="button" disabled v-if="loading2">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button> &nbsp;&nbsp;
                                    <button id="submit2" class="btn btn-secondary" @click="isApprove1 = false">No not yet</button>
                                </div>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
       </div>
       <div v-if="isVirtualCard">
        <transition name="modal">
            <div class="vcard-modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                
                               
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
       </div>
       
      <div v-if="isTransactionPin">
        <transition name="modal">
            <div class="pin-modal-mask">
                <div class="modal-wrapper col-md-3" style="margin:auto">
                    <div class="row">
                        <div class="col-md-0">
                        </div>
                        <div class="col-md-12">
                            <div class="row d-flex flex-column align-items-center">
                                <p class="text-black text-uppercase text-center mt-6">Create your 4-digits transaction pin</p>
                                <div class="inputs1"> 
                                    <input type="password" maxlength="4" id="text" placeholder="Enter Transaction Pin" class="form-control inputs-field1"  v-model="transaction_pin" required  ref="input1">
                                    <input type="password" maxlength="4" id="text" placeholder="Confirm Transaction Pin" class="form-control inputs-field1"  v-model="confirm_transaction_pin" required  ref="input1">
                                </div>
                            
                                <div class="btn-inputs">
                                    <button id="submit4" class="btn btn-primary btn-block mt-4 mb-4" :disabled="isSubmitButtonDisabled"  @click="create_pin()" v-if="!loading4">Create</button>
                                    <button class="btn btn-primary btn-block mt-4 mb-4" type="button" disabled v-if="loading4">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button class="btn btn-block" @click="isTransactionPin = false">X</button>
                                    <br>
                                </div>     
                            </div>
                        </div>
                        <div class="col-md-0">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
      </div>
        <p>&nbsp;</p>
      <page-loader :is-loading="isLoading"></page-loader>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {
  
  name: "HomeDashboard",
  components: {
    PageLoader
  },

  data(){
    return {
      stream: null, // Holds the media stream
        search: '',
        loading: false,
        loading2: false,
        isLoading: false,
        isLoading1: false,
        isApprove: false,
        isApprove1: false,
        isVirtualCard: false,
        isTransactionPin: false,
        tab1_view: true,
        tab2_view: false,
        isWithdrawal: false,
        isReferralWithdrawal: false,
        tab1: true,
        tab2: false,
        phone_number: '',
        email_address: '',
        user_id: '',
        firstname: '',
        lastname: '',
        bank_name:'',
        account_name:'',
        account_number: '',
        isAccountDetail: false,
        isAddfund: false,
        current_plan: '',
        current_plan_name: '',
        weekly_income: '',
        planid: '',
        expiry_date: '',
        expiry_status: '',
        referral_code: '',
        total_orders: '',
        referral_earnings: '',
        order_loading: false,
        referral_loading: false,
        active_loading: false,
        referral_count_loading: false,
        payment_history: false,
        payments:[],
        cummulative_earning: '',
        referral_count: 0,
        totalTasks: '',
        isWithdrawal_time: '',
        withdrawal_list: [],
        amount: '',
        active_referrals: [],
        referral_withd: '',
        img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
        header: [
          {
            align: 'start',
            value: 'package',
            sortable: false,
            text: 'Package',
          },
          { value: 'amount', text: 'Amount' },
          { value: 'paymentref', text: 'Payment Ref' },
          { value: 'date', text: 'Date' },
        ],
        desserts: [],

        header2: [
        {
          align: 'start',
          value: 'product_name',
          sortable: false,
          text: 'Product',
        },
        { value: 'img', text: 'Img' },
        { value: 'order_no', text: 'Order no.' },
        { value: 'date', text: 'Date' },
        { value: 'qty', text: 'Qty' },
        { value: 'total_amount', text: 'Amount' },
        { value: 'installment', text: 'Installment' },
        { value: 'duration1', text: 'Duration' },
        { value: 'paid', text: '%Paid' },
        { value: 'buyer', text: 'Customer' },
      ],
        desserts2: [],
        withdrawalCount: 0,
        user_bank_detail: '',
        wallet_balance: 0,
        contract_detail: [],
        is_products: false,
        active_orders: '',
        ordered_items: '',
        valid_shops: '',
        shipping: '',
        completed_contract_detail: [],
        bank_detail: [],
        payment_details: [],
        fullname: '',
        contractid: '',
        contract_user_payment: '',
        contract_number: '',
        pending_deposit: '',
        isShowBal: true,
        transaction_pin: '',
        confirm_transaction_pin: '',
        loading4: '',
        user_pin: '',
      };
  },

  beforeMount(){
    this.user_id = localStorage.getItem("userid");
  },

  mounted(){
      this.firstname = localStorage.getItem("firstname");
      this.lastname = localStorage.getItem("lastname");
      this.phone_number = localStorage.getItem("phone");
      this.email_address = localStorage.getItem("email");
      this.user_id = localStorage.getItem("userid");
      this.referral_code = localStorage.getItem("referral_code");
      //this.user_pin = localStorage.getItem("transaction_pin");
      this.fullname = this.firstname +' '+ this.lastname 

      //this.fetchActiveContracts(this.user_id);
      //this.fetchCompletedContracts(this.user_id);
      this.fetchBankDetails(this.user_id)
      this.fetchPaymentDetails(this.user_id)
      //this.fetchWalletBalanceByUserId(this.user_id)
     //this.fetch_pending_deposit(this.user_id)
      //this.fetch_user_pin()
      
  },

  created(){
        
  },

  computed: {

    cummulativeEarnings(){
      return this.total_orders + this.referral_earnings;
    },
    indexSn() {
      return this.desserts.map((d, index) => ({
          ...d,
          sn: index + 1
      }))
    },
    isSubmitButtonDisabled() {
        return (
          this.transaction_pin === '' ||
          this.confirm_transaction_pin === ''
        );
    }
  },

  methods: {
    async startCamera() {
      try {
        // Request camera access
        this.stream = await navigator.mediaDevices.getUserMedia({ video: true });

        // Assign the stream to the video element
        const videoElement = this.$refs.video;
        videoElement.srcObject = this.stream;
      } catch (error) {
        console.error("Error accessing camera:", error);
        alert("Could not access the camera. Please allow permissions.");
      }
    },
    async fetch_user_pin(){
      try {
              const response = await this.$http.post('/fetch-pin', {
                user_id: this.user_id
              })         
              this.user_pin = response.data.data  
              // load create transaction pin page if user not having pin
              if (!this.user_pin) {
                this.isTransactionPin = true
              }  

          } catch (err) {
              console.log(err);
          }
    },
    async create_pin(){
      try {
              if (this.transaction_pin != this.confirm_transaction_pin) {
                swal.fire({
                    icon: 'warning',
                    title: 'Pin Creation',
                    width: 450,
                    height: 5,
                    text: 'Transaction pin does not match',
                })
                return;
              }
              this.loading4 = true
              const response = await this.$http.post('/create-pin', {
                transaction_pin: this.transaction_pin,
                confirm_transaction_pin: this.confirm_transaction_pin,
                user_id: this.user_id
              })
              this.loading4 = false
              if (response.data.success) {
                swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    this.isTransactionPin = false
                    return;
                }
                else if (!response.data.success) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Could not create pin. An error occured!',
                    })
                    return;
                }  
             
          } catch (err) {
              console.log(err);
          }
    },
    buy_airtime(){
      this.$router.push('/buy/airtime')
    },
    buy_data(){
      this.$router.push('/buy/data')
    },
    buy_electricity(){
      this.$router.push('/purchase/electricity')
    },
    buy_cabletv(){
      this.$router.push('/subscribe/tv')
    },
    toggle(){
       if (this.isShowBal) {
        this.isShowBal = false
       }
       else{
        this.isShowBal = true
       }
    },
    add_fund(){
      this.$router.push('/topup-account')
    },
    withd_fund(){
      this.$router.push('/withdrawal')
    },
    async fetch_pending_deposit(user_id) {
          try {
              const response = await this.$http.post('/pending-deposit', {
                user_id: user_id
              })
              if (!response.data.wallet_list[0].totalDeposit) {
                this.pending_deposit = '0.00'
              }else{
                this.pending_deposit = response.data.wallet_list[0].totalDeposit
              }

          } catch (err) {
              console.log(err);
          }
    },
    async credit_user_wallet(contract_number, user_id) {
          try {
              await this.$http.post('/fetch-user-payment-role', {
                contract_number: contract_number,
                user_id: user_id
              })
          } catch (err) {
              console.log(err);
          }
    },
    processPayment(contract_id, payment_status){
        if (payment_status == 1) {
          swal.fire({
              icon: 'info',
              title: 'Awaiting payment approval',
              width: 450,
              height: 5,
              //text: 'Payment already made. Waiting for approval',
          })
          return;
        }
        else{
          this.$router.push('/checkout/summary/'+contract_id)
          return;
        }
    },
    copyToClipboard1(link) {
           // var copyText = document.getElementById("myInput");
            navigator.clipboard.writeText(link).then(function() {
                alert("Contract link copied to clipboard!");
            }).catch(function(err) {
                console.error('Failed to copy: ', err);
            });
    },
   
    create_escrow(){
      this.$router.push('/contract')
    },
    confirmShipping(payment_status, contract_id, pay_status){

      if( !payment_status && pay_status == 1 ){
            swal.fire({
            icon: 'info',
            title: 'Awaiting payment approval',
            width: 450,
            height: 5,
            //text: 'Payment already made',
        })
        return;
      }
          
      this.isApprove = true
      this.contractid = contract_id
    },
    async shipped(){
      try {
       
               const response =  await this.$http.post('/shipped-item', {
                  contractid: this.contractid,
                  fullname: this.fullname 
                })
                if(response.data.success){
                swal.fire({
                      icon: 'success',
                      title: 'Success',
                      width: 450,
                      height: 5,
                      text: 'You have agreed that you have shipped this item',
                })
                this.fetchActiveContracts(this.user_id)
                this.fetchCompletedContracts(this.user_id);
                this.fetchPaymentDetails(this.user_id)
                this.isApprove = false
                return;
              }
            } catch (err) {
              console.log(err);
          }
    },
    confirmReceipt(contract_status, contract_id, contract_number, pay_status){

      if( !contract_status && pay_status == 1 ){
            swal.fire({
            icon: 'info',
            title: 'Awaiting payment approval',
            width: 450,
            height: 5,
        })
        return;
      }
     
      this.isApprove1 = true
      this.contractid = contract_id
      this.contract_number = contract_number
      //console.log(contract_status+'-'+pay_status+'-'+this.contractid+'-'+this.contract_number)
    },
    async received(){
      try {
              this.loading2 = true
              const response =  await this.$http.post('/received-item', {
                  contractid: this.contractid ,
                  fullname: this.fullname 
              })
              this.loading2 = false
              if( response.data.success ){
                swal.fire({
                      icon: 'success',
                      title: 'Success',
                      width: 450,
                      height: 5,
                      text: 'You have accepted that you have received this item',
                })
                this.credit_user_wallet(this.contract_number, this.user_id)
                this.fetchActiveContracts(this.user_id)
                this.fetchCompletedContracts(this.user_id);
                this.fetchPaymentDetails(this.user_id)
                this.isApprove1 = false
                return;
              }
            
            } catch (err) {
              console.log(err);
          }
    },
    viewContract(contract_id) {
        this.$router.push('/view-contract/' + contract_id)
    },
    async fetchShippingByUserId() {
            try {
              this.isLoading = true
              this.desserts = []
               const response =  await this.$http.post('/fetch-shipping-byuserid', {
                  userid: this.user_id
                })
              this.isLoading = false
              this.shipping = response.data.data
               
            } catch (err) {
              console.log(err);
          }
    },

    async fetchWalletBalanceByUserId(userid) {
            try {
              this.isLoading = true
              this.desserts = []
               const response =  await this.$http.post('/fetch-wallet-balance', {
                  userid: userid
                })
              this.isLoading = false
              this.wallet_balance = response.data.balance
               
            } catch (err) {
              console.log(err);
          }
    },

    async validateShop(userid){
      try {
          const response =  await this.$http.post('/fetch-shop-byuserid', {
            userid: userid,
          })
          this.valid_shops = response.data.data
          return;
          
        } catch (err) {
        console.log(err);
        }
    },

    async validateBank(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-bank', {
                  userid: userid,
                })

                this.user_bank_detail = response.data.bank
                    
            } catch (err) {
              console.log(err);
          }
    },

    async fetchActiveContracts(userid){
        try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-active-contracts', {
              user_id: userid,
            })
            this.contract_detail = response.data.data;
            this.isLoading = false
            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async fetchCompletedContracts(userid){
        try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-completed-contracts', {
              user_id: userid,
            })
            this.completed_contract_detail = response.data.data;
            this.isLoading = false
            return;
            
          } catch (err) {
          console.log(err);
          }
    },
    
    async fetchBankDetails(userid){
        try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-bank-detail', {
              user_id: userid,
            })
            this.bank_detail = response.data.data[0];
            this.isLoading = false
            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async fetchPaymentDetails(userid){
      try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-utility-transactions-by-userid', {
              userid: userid,
            })
            this.payment_details = response.data.data;
            this.isLoading = false
            return;
            
      } catch (err) {
          console.log(err);
      }
    },
 
    async paymentHistory() {
      try {
            this.payment_history = true
            const response =  await this.$http.post('/payment-history', {
                userid: this.user_id
            })
            this.payment_history = false
            this.payments = response.data.payments;
            this.payments.forEach(el => {
            this.desserts.push({
              package: el.plan_name,
              amount: 'NGN'+el.amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
              paymentref: el.payment_ref,
              date: new Date(el.created_at).toDateString()
            })
          }); 
        } catch (err) {
          console.log(err);
      }
    },
   
    viewTab1(){
      this.tab1_view = true
      this.tab2_view = false
    },
    viewTab2(){
      this.tab1_view = false
      this.tab2_view = true
    },
    copyToClipboard() {
      var copyText = document.getElementById("myInput");
      navigator.clipboard.writeText(copyText.value).then(function() {
          alert("Referral link copied!");
      }).catch(function(err) {
          console.error('Failed to copy: ', err);
      });
    },
    marketplace(){
      this.$router.push("/myorders")
    }
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.container-fluid{
  background: #cccccc41;
  border-radius: 10px;
  padding: 0 40px 0 15rem;
  min-height: 100vh;
}
.fa-eye{
  cursor: pointer;
  //font-size: 10px;
}
.copy-refid1{
  cursor: pointer;
  //font-size: 20px;
}

.pin-modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 80%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Poppins;
  }

}
.inputs1{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  border-radius: 3px;
  font-size:11px;
  font-weight: 400;
  width: 100%;
  padding: 0.6rem;

  .inputs-field1{
      font-size: 14px;
      font-weight: bold;
      font-family: Poppins;
      text-align: center;
      height: 40px;
      margin: 5px;
      border: solid 1px;
  }
}
.btn-primary1{

  border-radius: 9px;
  background: #24562B;
  color: #fff;
  text-transform: uppercase;
  //font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}
.btn-primary{
  width: 100%;
  height: 52px;
  padding: 0px 36.19px 0px 36.19px;
  border-radius: 9.05px;
  background: #24562B;
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
}
.modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 90%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      ;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Poppins;
  }

  h3 {
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      font-family: Poppins;
  }
}
.vcard-modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 90%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      ;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Poppins;
  }

  h3 {
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      font-family: Poppins;
  }
}
.search-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.inputs-panel{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 239px;
  height: 48px;
  border-radius: 102px;
  border: 1px solid #B7BFC5;
  padding: 0.9rem
}
.input-field{
  border-right: 1px solid #B7BFC5;
}
.account-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-content-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 10px;
  padding-top: 1.3rem;
  text-transform: uppercase;
  text-align: center;
}

.add-fund {
  font-size: 12px;
  text-decoration: none !important;
  text-transform: capitalize;
}

.account-content-d {
  padding-top: 1.3rem;
  font-size: 14px;
  margin-bottom: 10px;
}

.btn-secondary {
  text-transform: normal;
  background: #24562B;
  color: #fff;
  border-radius: 8px;
}
.btn-info {
  text-transform: normal;
  background: #24562B;
  color: #fff;
  border-radius: 8px;
}
.btn-primary {
  text-transform: normal;
  background: #24562B;
  color: #fff;
  border-radius: 8px;
}
.welcome{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  padding: 0px 20px;
  text-align: left;
  color: #000;
}

.dashboard-page{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #cccccc32;
    padding: 0.6rem;
    font-family: Poppins;

    strong{
      font-weight: normal;
      font-family: Poppins;
    }
 }

 .wallet-board{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
    height: 100%;
 }

 .wallet-account{
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background: #24562B;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
 }
 .account-note{
  border-bottom: solid 1px #ccc;
  padding: 0.6rem;

    p{
      font-family: Poppins;
      font-weight: normal;
      font-size: 11px;
      line-height: 1.2;
      color: #ccc;
    }

 }
 .account-number{
    padding: 0.6rem;
  
  div{
    font-family: Poppins;
    font-weight: normal;
    font-size: 10px;
    color: #ccc;

    span{
      color: #fff;
    }
  }
 }
 .wallet-balance{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: 60%;
    height: 100%;
    //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    
    h4{
      font-family: Poppins;
      font-weight: 600;
      font-size: 20px;
      color: #000;
      text-shadow: 1px 0px 1px #ccc;
    }
 }
 .withdrawal{
    font-family: Poppins;
    font-weight: normal;
    font-size: 12px;
    color: #24562B;
    border: solid 1px;
    padding: 0.2rem;
    border-radius: 4px;
 }
 .dashboard-inner{
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  padding: 18px 20px 18px 20px;
  border-radius: 16px;
  border: 3px solid #F4F4F4;
  background: var(--White-Original, #FFFFFF);
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
 }
 .dashboard-inner1{
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 18px 20px 18px 20px;
  border-radius: 16px;
  border: 0px solid #F4F4F4;
  background: var(--White-Original, #FFFFFF);
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  margin-top:-10px;
 }
 .sidebar::-webkit-scrollbar {
  display: none;
}

.active-escrow{
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
}
.create-escrow{
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  width: 130px;
  height: 30px;
  padding: 0.8rem;
  border-radius: 70.8px;
  background: var(--Primary-Color, #24562B);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer
}
 .dashboard-inner-new{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 255px;
  padding: 18px 20px 18px 20px;
  gap: 10px;
  border-radius: 16px;
  border: 3px solid #F4F4F4;
  background: var(--White-Original, #FFFFFF);
 }
 .wallet-board-new{
  display: flex;
  flex-direction: column;
 
  width: 100%;
  height: auto;
  padding: 0.9rem;
  border-radius: 13px;
  
  background: var(--Primary-Color, #24562B);
  color: #fff;

  .avail-amount{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;

    .avail-bal{
      //font-family: Montserrat;
      font-size: 12px;
      font-weight: 400;
    }
    .trans-his{
      //font-family: Montserrat;
      font-size: 12px;
      font-weight: 400;
      color: #D9D9D9;
    }

  }
  .tran-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .balance{
      font-size: 32px;
      font-weight: 400;
      text-align: left;
      display: flex;
    }
    .balance2{
      font-size: 32px;
      font-weight: 400;
      text-align: left;
      display: none;
    }
   .pending-history{
    display: flex;
    flex-direction: column;
   }
    .pending{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
      text-align: left;
      color: #D9D9D9;
    }
  }
 }
 .add-withd-panel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }
 .add-fund{
  width: Fixed (111.7px)px;
  height: Fixed (38px)px;
  padding: 12.67px 16.12px 12.67px 16.12px;
  gap: 11.52px;
  border-radius: 81.53px;
  
  background: var(--Primary-Color, #24562B);
  //font-family: Inter;
  font-size: 12.79px;
  font-weight: 500;
  line-height: 12.79px;
  text-align: left;
  color: #FFFFFF;
  margin-right: 4px;

 }
 .withd-fund{
  width: Fixed (112.59px)px;
  height: Hug (38px)px;
  padding: 12.67px 9.85px 12.67px 9.85px;
  gap: 14.07px;
  border-radius: 81.53px;
  
  background: var(--Secondary-color, #08294A);
  //font-family: Inter;
  font-size: 12.79px;
  font-weight: 500;
  line-height: 12.79px;
  text-align: left;
  color: #FFFFFF;

 }
 .active-c{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ECF3FF;
  width: 100%;
  height: 100px;
  border-radius: 12px;

  .icon{
    font-size: 44px;
  }
 }
 .active-c-history{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #cccccc32;
  border-radius: 4px;
  height: auto;
  width: 100%;
  padding: 0px;
  margin-top: 4px;

  .icon2{
    font-size: 20px;
  }
 }
 .activeo{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;  
  width: 100%;
 }
 .name1{
  color: #24562B;
  font-weight: 450;
  font-size: 14px;
  font-family: Poppins;
}
.get-virtual-card{
  width: 170px;
  height: 48px;
  border-radius: 102px;
  background: var(--Secondary-color, #08294A);
  color: #fff;
  font-family: Poppins;
}
.active-ccc{
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  width: 35%;
  font-size: 12px !important;
  font-family: Poppins;
}
.view-contract{
  font-size: 10px;
}
.active-cc{
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-family: Poppins;
  
  .name{
    color: #24562B;
    font-weight: 500;
    font-family: Poppins;
  }

  .location{
    color: #807b7b;
    font-weight: 550;
    font-size: 11px;
    font-family: Poppins;
    margin-top: 4px;
  }

  .amount{
    color: #24562B;
    font-weight: 500;
    font-family: Poppins;
  }

  .cost-price{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 10px;
    font-size: 10px;

    button{
      font-size: 10px;
    }
  }
  button{
    font-size: 10px;
  }

  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
  }

 }

 .active-withd{
  width: 80%;
 }
 .active-cc-history{
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    span{
      color: #00000091;
      font-size: 10px;
      font-weight: 500;
    }
  }
  .amount{
    color: #24562B;
    font-weight: normal;
    font-family: Poppins;
    font-size: 11px;
  }
  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }

  .amount-w{
    color: #ff2929;
    font-weight: normal;
    font-family: Poppins;
    font-size: 11px;
  }
  .date-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }

  .name-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    span{
      color: #24562B;
      font-style: italic;
    }
  }

 }

 .active-cc-user{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-content: center;
  padding: 0.6rem;
  font-family: Poppins;
 }
 .border-style-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px solid #F4F4F4;
  border-radius: 4px;
  background: #24562B;
  height: 40px;
  color: #fff;
  margin-top: -10px;
}
 .service-box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  border-radius: 5px;
  border: solid 0px #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  padding: 0.6rem;
  margin: 4px;
  cursor: pointer;
 }
 .active-c-user{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: auto;
  width: 100%;
  padding: 0px;
  margin-top: 4px;
  .icon3{
    font-size: 40px;
  }
 }
 .virtual-card-airtime-money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height:84px;
  padding: 12px 20px 12px 20px;
  border-radius: 5px;
  background: #24562B;
 }
 .virtual-card-airtime-money1{
  display: none;
 }
 .vcard{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 222.93px;
  height: 53.75px;
  padding: 10px;
  border-radius: 7.69px;
  background: var(--sub-layer, #ECF3FF);
  //font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
 }
 .airtime-v{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 222.93px;
  height: 53.75px;
  padding: 10px;
  border-radius: 7.69px;
  background: var(--sub-layer, #ECF3FF);
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
 }

 .utilities{
  display: flex;
  flex-direction: row;
  justify-content: space;
  align-items: center;
  width: 100%;
  height:84px;
  padding: 12px 20px 12px 20px;
  border-radius: 5px;
  background: #08294A;
}
.airtime-utility{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 226.86px;
  height: 39.86px;
  padding: 11.43px;
  border-radius: 5.71px;
  background: var(--sub-layer, #ECF3FF);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--Primary-Color, #24562B);
}
.utility-icons{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;

  .icons{
    margin: 3px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container-fluid{
        padding: 0 0px 0 0rem;
    }

  .pin-modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 90%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Poppins;
      
  }

}
  .inputs1{
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 3px;
  font-size:11px;
  font-weight: 400;
  padding: 0.4rem;
}

  .welcome{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    padding: 0px 15px;
    text-align: left;
  }

.active-escrow{
  font-size: 12px;
}
.create-escrow{
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  width: 105px;
  height: 30px;
  padding: 0.2rem;
  border-radius: 70.8px;
  background: var(--Primary-Color, #24562B);
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer
}

.search-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px 0px 15px;
}
.inputs-panel{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 40px;
  border-radius: 102px;
  border: 1px solid #B7BFC5;
  padding: 0.8rem;

  .input-field{
    border-right: 1px solid #B7BFC5;
    width: 120px;
  }
}

.get-virtual-card{
  font-size: 12px;
  width: 140px;
  height: 40px;
}

  .dashboard-page{
    display: flex;
    flex-direction: column;
    strong{
      margin-top: 10px;
    }
 }
 .dashboard-inner{
  margin: 0px;
  margin-top: 10px;
  padding: 0.5rem;
  //padding: 18px 10px 18px 10px;
 }

 .dashboard-inner-new{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 255px;
  padding: 10px 15px 10px 15px;
  border-radius: 16px;
  border: 3px solid #F4F4F4;
  background: var(--White-Original, #FFFFFF);
 }
 .active-ccc{
  display: flex;
  flex-direction: column;
  font-size: 10px !important;
  margin-left: 0px;

  .btn-size{
    font-size: 9px !important;
  }
}

.name1{
  font-size: 11px !important;
}
.view-contract{
  font-size: 8px !important;
  width: 80px;
}
 .wallet-board-new{
  display: flex;
  flex-direction: column;
 
  width: 100%;
  height: auto;
  padding: 0.9rem;
  border-radius: 13px;
  
  background: var(--Primary-Color, #24562B);
  color: #fff;

  .avail-amount{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;

    .avail-bal{
      //font-family: Montserrat;
      font-size: 11px;
      font-weight: 400;
    }
    .trans-his{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: normal;
      color: #D9D9D9;
    }

  }
  .tran-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .balance{
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
    }
   .pending-history{
    display: flex;
    flex-direction: column;
   }
    .pending{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: normal;
      color: #D9D9D9;
    }
    .pending-fund{
      //font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
    }
  }
 }
 .add-withd-panel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }
 .add-fund{
  width: Fixed (111.7px)px;
  height: Fixed (38px)px;
  padding: 12.67px 16.12px 12.67px 16.12px;
  border-radius: 81.53px;
  
  background: var(--Primary-Color, #24562B);
  //font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.79px;
  text-align: left;
  color: #FFFFFF;
  margin-right: 4px;

 }
 .withd-fund{
  width: Fixed (112.59px)px;
  height: Hug (38px)px;
  padding: 12.67px 9.85px 12.67px 9.85px;
  border-radius: 81.53px;
  
  background: var(--Secondary-color, #08294A);
  //font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.79px;
  text-align: left;
  color: #FFFFFF;

 }

 .active-c{

  .icon{
    font-size: 40px;
  }
 }
 .activeo{
  //margin-left: -6px;
 }
 .active-cc{
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
  font-family: Poppins;
  width: 100%;

  .name1{
    color: #24562B;
    font-weight: 500;
    font-family: Poppins;
    font-size: 10px;
  }

  .name{
    color: #24562B;
    font-weight: 500;
    font-family: Poppins;
    font-size: 10px;
  }

  .location{
    color: #807b7b;
    font-weight: 200;
    font-family: Poppins;
    margin-top: 10px;
    font-size: 10px;
  }

  .amount{
    color: #24562B;
    font-weight: 500;
    font-family: Poppins;
    font-size: 10px;
  }

  .cost-price{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 10px;
    font-size: 10px;
  }

  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
  }

 }
 .wallet-board{
  padding: 0.5rem;
}

.account-note{
  border-bottom: solid 1px #ccc;
  padding: 0.4rem;

    p{
      font-family: Poppins;
      font-weight: normal;
      font-size: 11px;
      line-height: 1;
    }

 }
 .account-number{
    padding: 0.4rem;
  
  div{
    font-family: Poppins;
    font-weight: normal;
    font-size: 10px;
    color: #ccc;
    span{
      color: #fff;
      font-size: 11px;
    }
  }
 }
 .wallet-balance{
    h4{
      font-weight: normal;
      font-size: 14px;
      color: #000;
      text-shadow: 1px 0px 1px #ccc;
    }
 }
 .withdrawal{
    font-family: Poppins;
    font-weight: normal;
    font-size: 10px;
    color: #24562B;
    border: solid 1px;
    padding: 0.2rem;
 }

 .active-c-history{
  padding: 0px;
  margin-top: 10px;
  .icon2{
    font-size: 15px;
  }
 }

 .active-cc-history{
  padding: 0.2rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
    
    span{
      color: #00000091;
      font-style: italic;
    }
  }
  .amount{
    color: #24562B;
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }
  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 8px;
  }

  .amount-w{
    color: #ff2929;
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }
  .date-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 8px;
  }

  .name-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
    span{
      color: #24562B;
      font-style: italic;
    }
  }

 }
 .dashboard-inner1{
  display: flex;
  flex-direction: column;
  padding: 18px 10px 18px 10px;
 }
 .active-cc-user{
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-content: center;
  //padding: 0rem;
  font-family: Poppins;
  width: 100%;
 }
 .border-style-header{
  width: 100%;
}
 .service-box {
  width: 100%;
 }
 .active-c-user{
  padding: 0px;
  margin-top: 4px;
  .icon3{
    font-size: 23px;
  }
 }
 .account-content {
  margin: 0px;
  margin-right: 0px;
}
.virtual-card-airtime-money{
  display: none;
 }
.virtual-card-airtime-money1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height:84px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  background: #24562B;
 }
 .vcard{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0px;
  border-radius: 7.69px;
  background: var(--sub-layer, #ECF3FF);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
 }
 .airtime-v{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0px;
  border-radius: 7.69px;
  background: var(--sub-layer, #ECF3FF);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

 }
 .util-m{
  margin: 2px;
 }
 .utilities{
  padding: 12px 20px 12px 10px;
}
 .airtime-utility{
  display: none;
 }

 .utility-icons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  padding-left: 0px;
  
  .icons{
    cursor: pointer;
  }
}
  
}

</style>