<template>
    <div class="container-fluid">
        <div id="splash-message" class="splash-message"></div>
        <p class="welcome"></p>
         <div class="col-lg-12 col-sm-12 pt-3 dashboard-page"> 
              <div class="col-lg-6 col-sm-6 pt-3" v-if="payment_banks != ''">
                <div class="dashboard-inner-new">
                    <div class="wallet-board-new">
                      <div class="avail-amount">
                        <div class="topup">Bank Transfer</div>
                        <div class="add-money">Pay via mobile or internet banking</div>
                      </div>
                    </div>
                    <div class="wallet-board-new-c mt-2">
                        <div class="avail-amount-c">
                          <div class="amount-cc">Transfer to the following account details</div>
                          <div class="trans-expired">Transaction will expire in</div>
                          <div class="trans-time">{{countdown_Timer}}</div>
                          <div class="trans-amount-send">Amount you will send</div>
                          <div class="trans-amount-charge">₦{{ payment_banks.amount_to_pay  }}</div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column justify-content-between">
                        <div class="account-number-text">Account Number</div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <div class="account-number">{{ payment_banks.account_number}}</div>
                            <button @click="copyToClipboard(payment_banks.account_number)" class="copy-number">Copy Number</button>
                            <button @click="copyToClipboard(payment_banks.account_number)" class="copy-number1"><i class="fa fa-copy"></i></button>
                        </div>
                    </div>
                    <div class="wallet-board-new-t amount mt-4">
                      <div class="bank-acc-name">
                        <h4 class="bank-name-text">Bank Name</h4>
                        <h4 class="account-name-text">Account Name</h4>
                      </div>
                      <div class="bank-acc-number">
                        <h4 class="bank-name">{{ payment_banks.bank_name}}</h4>
                        <h4 class="account-name">{{ 'Prolegacy Multiservices Limited'}}</h4>
                      </div>
                    </div>
                    <div class="wallet-board-new-t amount mt-4">
                        <div class="bank-acc-name">
                          <h4 class="bank-name-text reference-text">Narration/Ref: <span class="reference">{{ payment_banks.invoice_reference+'-'+payment_banks.wp_reference	}}</span></h4>
                          <button @click="copyToClipboard1(payment_banks.invoice_reference+'-'+payment_banks.wp_reference)" class="copy-number-t"><i class="fa fa-copy"></i></button>
                        </div>
                    </div>
                    <div class="add-withd-panel-enter-p">
                      <p> Please wait and do not leave this page. We are confirming your payment. <span style="font-size: 15px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span class="text-danger">Checking for payment...</span></p>
                    </div>
                    <div class="add-withd-panel-enter-p"><button @click="confirm_payment()" class="proceed">Return to Dashboard</button></div>
                    <!-- <div class="add-withd-panel-enter-p"><button class="proceed" @click="confirm_payment()"> I have made payment</button></div> -->
                </div>
                <div class="dashboard-inner-t mt-4 pt-4 pl-10 pr-10">
                    <h5 class="pls-remember">PLEASE REMEMBER:</h5>
                    <ol>
                        <li>
                           <p>{{payment_banks.instruction}}</p>
                        </li>
                    </ol>
                </div>
              </div>            
              <div class="col-lg-6 col-sm-6 pt-3" v-else>
                <div class="dashboard-inner-new">
                    <center>
                        <span style="font-size: 24px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <p>Generating payment details. Please wait...{{countdown_Timer1}}</p>
                    </center>
                </div>
              </div>
            </div>

            <div v-if="isPayment">
              <transition name="modal">
                  <div class="modal-mask">
                      <div class="modal-wrapper col-md-5" style="margin:auto">
                          <div class="row">
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-10">
                                  <div class="row account-content">
                                      <div class="account-content-d">
                                          <div class="inputs ">
                                              <center>
                                              <h3 class="text-info"><u>Payment status</u></h3>
                                          
                                              <div class="a-text1">
                                                <h2 class="text-warning">You have made an Incomplete Payment.</h2>
                                                <h4>Please pay the complete amount</h4>
                                              </div>
                                              </center>
                                          </div>
                                      </div>
                                      <button id="submit2" class="btn btn-default font-weight-bold" @click="confirm_payment()">X</button>
                                      <p>&nbsp;</p>
                                  </div>
                              </div>
                              <div class="col-md-1">
                              </div>
                          </div>
                      </div>
                  </div>
              </transition>
            </div>
            <div v-if="isPaymentSuccess">
              <transition name="modal">
                  <div class="modal-mask">
                      <div class="modal-wrapper col-md-5" style="margin:auto">
                          <div class="row">
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-10">
                                  <div class="row account-content">
                                      <div class="account-content-d">
                                          <div class="inputs ">
                                              <center>
                                              <h2 class="text-info"><u>Payment Successful</u></h2>
                                          
                                              <div class="a-text1">
                                                <h3 class="text-success">{{ this.pay_method }} purchase is successful!</h3>
                                                <h4>Thank you for choosing PlePower</h4>
                                              </div>
                                              </center>
                                          </div>
                                      </div>
                                      <button id="submit2" class="btn btn-default font-weight-bold" @click="confirm_payment()">X</button>
                                      <p>&nbsp;</p>
                                  </div>
                              </div>
                              <div class="col-md-1">
                              </div>
                          </div>
                      </div>
                  </div>
              </transition>
            </div>
          <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "TopupPage",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          isLoading: false,
          loading: false,
          isPayment: false,
          isPaymentSuccess: false,
          phone_number: '',
          email_address: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          amount: '',
          reference_number: '',
          payment_banks: [],
          timer: null, 
          timer2: '',
          countdown_Timer: '',
          countdown_Timer1: '',
          interval: '',
          interval2: '',  // Store the interval ID
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
          selectedPaymentMethod: '',
          contract_id: '',
          transaction_details: '',
          network: '',
          mobile_phone: '',
          data_plan: '',
          utility: '',
          pay_method: '',
          timeoutId: null,  // Store the timeout ID
          intervalId: null, // Store the interval ID
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.referral_code = localStorage.getItem("referral_code");
        this.fullname = this.firstname +' '+ this.lastname 
        this.utility = this.$route.params.utility

        if (this.utility === 'airtime') {
            this.amount = this.$route.params.amount
        }
        if (this.utility === 'data') {
            const array = this.$route.params.amount.split(' ');
            this.amount = array[0]
            this.data_plan = array[1]
        }
        if (this.utility === 'cabletv') {
            const array = this.$route.params.amount.split(' ');
            this.amount = array[0]
            this.data_plan = array[1]
        }
        
        this.reference_number = this.firstname+'_'+ Date.now()
        this.mobile_phone = this.$route.params.phone
        this.network = this.$route.params.network
       
        this.quickPayment(this.amount, this.reference_number, this.phone_number);
        this.countDownTimer1();
        this.paymentMade()
    },
  
    created(){
          
    },
  
    computed: {
      cummulativeEarnings(){
        return this.total_orders + this.referral_earnings;
      },
      indexSn() {
        return this.desserts.map((d, index) => ({
            ...d,
            sn: index + 1
        }))
      },
      isSubmitButtonDisabled() {
          return (
            this.amount === ''
          );
      }
    },
  
    methods: {
      go_back(){
        this.$router.push('/contract')     
      },
      confirm_payment(){
        this.stopTimer2()
        this.$router.push('/home')
        //this.$router.push('/payment-successful/'+this.reference_number+'/'+this.amount+'/'+this.payment_banks.bank_name+'/'+this.payment_banks.account_name+'/'+this.payment_banks.account_number)
      },
     
      processPayment(contract_id){
          this.$router.push('/checkout/summary/'+contract_id)
      },
      copyToClipboard(link) {
          navigator.clipboard.writeText(link).then(function() {
              alert("Account number copied to clipboard!");
          }).catch(function(err) {
              console.error('Failed to copy: ', err);
          });
      },
      copyToClipboard1(link) {
          navigator.clipboard.writeText(link).then(function() {
              alert("Reference number copied to clipboard!");
          }).catch(function(err) {
              console.error('Failed to copy: ', err);
          });
      },
      async quickPayment(amount, reference_number, phone_number){
        try {
                const response = await this.$http.post('/quick-payment', {
                    amount: amount,
                    reference_number: reference_number,
                    phone_number: phone_number
                })
                
                if (!response.data.success) {
                    swal.fire({
                          icon: 'info',
                          title: 'Utility Payment',
                          width:450,
                          height:5,
                          text: response.data.message,
                    });
                    return;
                } 

                this.payment_banks = response.data.data  

                if(this.payment_banks != ''){
                  
                   clearInterval(this.interval2);
                    let service = ''
                    if (this.utility === 'airtime') {
                        service = 'airtime-purchase'
                        this.pay_method = 'airtime'
                    }
                    if (this.utility === 'data') {
                        service = this.data_plan
                        this.pay_method = 'data'
                    }
                    if (this.utility === 'cabletv') {
                        service = this.data_plan
                        this.pay_method = 'cabletv'
                    }

                  await this.$http.post('/create-payment-dump', {
                    contractid: this.user_id,
                    selectedPaymentMethod: this.pay_method,
                    amount: this.amount,
                    userid: this.user_id,
                    transaction_reference: this.reference_number,
                    fullname: this.firstname + ' ' + this.lastname,
                    receiver_fullname: this.firstname + ' ' + this.lastname,
                    bank_name: this.network,
                    account_name: service,
                    account_number: this.mobile_phone,
                  })

                  this.countDownTimer();
                 
                  this.paymentMade(); // call i have made payment
                }
            } catch (err) {
                console.log(err);
            }
      },

      async countDownTimer() {
            // Set the countdown time in seconds
            var countdownTime = 1200; // 5 minutes = 5 * 60 seconds

            // Update the countdown timer every second
            this.interval = setInterval(() => {
                // Calculate minutes and seconds
                var minutes = Math.floor(countdownTime / 60);
                var seconds = countdownTime % 60;

                // Display the time in the HTML element
                this.countdown_Timer = minutes + ":" + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});

                // Check if the countdown has finished
                if (countdownTime == 0) {
                  this.stopTimer(); // Stop the interval
                    this.countdown_Timer = "00:00";
                    //this.$router.push('/topup-account');
                }

                // Decrease the countdown time by 1 second
                countdownTime--;
            }, 1000);
      },

      async countDownTimer1() {
            // Set the countdown time in seconds
            var countdownTime = 60; // 5 minutes = 5 * 60 seconds

            // Update the countdown timer every second
            this.interval2 = setInterval(() => {
                // Calculate minutes and seconds
                var minutes = Math.floor(countdownTime / 60);
                var seconds = countdownTime % 60;

                // Display the time in the HTML element
                this.countdown_Timer1 = minutes + ":" + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});

                // Check if the countdown has finished
                if (countdownTime == 0) {
                  this.stopTimer2();
                  this.countdown_Timer1 = "00:00";
                  swal.fire({
                      icon: 'info',
                      title: 'Account Creation',
                      width:450,
                      height:5,
                      text: 'Could not generate account details',
                  });
                  this.$router.push('/home')
                  return;
                }

                // Decrease the countdown time by 1 second
                countdownTime--;
            }, 1000);
      },

      stopTimer() {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;  // Reset the interval ID
        }
      },
      
      stopTimer2() {
        if (this.interval2) {
          clearInterval(this.interval2);
          this.interval2 = null;  // Reset the interval ID
        }
      },

      paymentMade(){
            try{
                
              setTimeout(() => {
                    // Execute the function for the first time
                    this.checkTransactionStatus('/query-payment-status');

                    // Then repeat execution every 3 minutes
                    setInterval(() => {
                      this.checkTransactionStatus('/query-payment-status');
                    }, 60000);  // 180,000 milliseconds = 3 minutes
                  }, 120000);  // Initial delay of 3 minutes
                                                
            }catch (err) {
                console.log(err);
            }
      },

      stopPaymentExecution() {
        // Clear the timeout and interval when needed
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null; // Reset the timeout ID
        }
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null; // Reset the interval ID
        }
      },

        async checkTransactionStatus(query_url){
            try{
                    this.response = await this.$http.post(`${query_url}`, {
                        reference_number: this.reference_number,
                    });

                    if (this.response.data.data) {
                                           
                        this.transaction_details = this.response.data.data.status
                             
                        if (this.transaction_details == 'Completed') { 
                                                   
                          this.isPaymentSuccess = true  
                          let service = ''
                          if (this.utility === 'airtime') {
                                service = 'airtime-purchase'
                                this.pay_method = 'airtime'
                          }
                          if (this.utility === 'data') {
                                service = this.data_plan
                                this.pay_method = 'data'
                          }
                          if (this.utility === 'cabletv') {
                                service = this.data_plan
                                this.pay_method = 'cabletv'
                          }
 
                            // update order table
                            await this.$http.post('/create-payment', {
                                contractid: this.user_id,
                                selectedPaymentMethod: this.pay_method,
                                amount: this.amount,
                                userid: this.user_id,
                                transaction_reference: this.reference_number,
                                fullname: this.firstname + ' ' + this.lastname,
                                receiver_fullname: this.firstname + ' ' + this.lastname,
                                network: this.network,
                                service: service,
                                mobile_phone: this.mobile_phone,
                            })
                            // Stop checking payment status
                            this.stopPaymentExecution();                                                  
                            return;
                            
                        }
                        else if(this.transaction_details == 'Underpayment') {

                          clearInterval(this.interval);
                          this.isPayment = true
                          //this.showSplashMessage("Our system has received an incomplete payment from you.")  
                          this.stopPaymentExecution();  
                        }
                        return;              
                }            
            }catch (err) {
                console.log(err);
            }
        },

        stopSendingRequests() {
            clearInterval(this.interval2);
        },

      showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
            this.$router.push('/home')
        }, 15000); // Hide the message after 2 seconds
      },
     
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
  .container-fluid{
  background: #cccccc41;
  border-radius: 10px;
  padding: 0 40px 0 15rem;
  min-height: 100vh;
}
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  .copy-refid1{
    cursor: pointer;
  }
  .modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        ;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Poppins;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Poppins;
    }
  }
  
  .account-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .account-content-h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  
  .add-fund {
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d {
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .btn-secondary {
    text-transform: normal;
  }
  .welcome{
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 20px;
    text-align: left;
    color: #170e0fac;
  }
   .dashboard-page{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: transparent;
      padding: 0.6rem;
      font-family: Poppins;
      strong{
        font-weight: normal;
        font-family: Poppins;
      }
   }
   .wallet-board{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      //background: #807b7b33;
      border-radius: 4px;
      padding: 0.5rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
      height: 100%;
   }
   .wallet-account{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background: #2979FF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   }
   .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.6rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1.2;
        color: #ccc;
      }
  
   }
   .wallet-balance{
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      width: 60%;
      height: 100%;
      //background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      
      h4{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 12px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
      border-radius: 4px;
   }
   .dashboard-inner{
    overflow-y: auto;
    height: 255px;
    width: 100%;
    border-radius: 15px;
    padding: 1.2rem;
    margin: 10px;
    background: #fff;
   }
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 19px;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #24562B);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
  
      .topup{
        font-size: 28.69px;
        font-weight: 500;   
      }
      .add-money{
        font-size: 9px;
        font-weight: normal;
        color: #fff;
      }
  
    }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
       // font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        text-align: left;
  
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 600;
        text-align: left;
        color: #D9D9D9;
      }
    }
   }

   .wallet-board-new-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
  
    .avail-amount-c{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
    }
    .amount-cc{
       // font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        color: #181818;
    }
    .trans-expired{
        font-size: 13px;
        font-weight: 300;
        color: #181818;
    }
    .trans-time{
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        color: #F22323;
    }
    .trans-amount-send{
        //font-family: Montserrat;
        font-size: 12.04px;
        font-weight: 400;
        text-align: center;
        color: #414244;
    }
    .trans-amount-charge{
       // font-family: Montserrat;
        font-size: 40px;
        font-weight: 500;
        color: #24562B;
    }
   }
   .wallet-board-new-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #24562B);
    color: #fff;
   }
   .account-number-text{
    font-size: 14px;
    font-weight: 400;
    color: #181818;
   }
   .account-number{
    font-size: 30px;
    font-weight: 501;
    color: var(--DIM, #7F7F7F);
    padding: 0rem;
   }
   .copy-number1{
    display: none;
   }
   .copy-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 34px;
    border-radius: 72px;
    background: var(--Primary-Color, #24562B);
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
   }
   .copy-number-t {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;
    padding: 0.2rem;
    height: 34px;
    border-radius: 72px;
    background: var(--Primary-Color, #24562B);
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
   }
   .bank-acc-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
   }
   .bank-acc-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -12px;
   }
   .bank-name-text{
    //font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    color: #181818;
   }
   .account-name-text{
    //font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    color: #181818;
   }
   .bank-name{
    //font-family: Montserrat;
    font-size: 17px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .account-name{
    //font-family: Montserrat;
    font-size: 17px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .reference-text{
    font-size: 14px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
   }
   .reference{
    font-size: 14px;
    font-weight: 500;
    color: var(--DIM, #252323);
    text-align: center;
   }
   .dashboard-inner-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    //padding: 19px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .pls-remember{
    //ont-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
    ;
   }
   ol li{
     font-size: 10px;
     color: #24562B;
     padding: -0.8rem;
   }
   ol li p{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
   // line-height: 10px;
    text-align: justify;
    color: var(--DIM, #7F7F7F);
   }
  
   .amount-p{
    color: var(--DIM, #7F7F7F);
   }
   .amount{
    padding: 0.6rem !important;
    background: var(--sub-layer, #ECF3FF) !important;
   }
   .input-amount-symbol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #7F7F7F;

    span{
      color: #2D2D2D;
      //font-family: Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      width: 21px;
      height: 20px;
      gap: 0px;
      

    }
   }
   .input-amount{
    width: 100%;
    height: 32px;
    gap: 0px;
    
    background: #FFFFFF;
   }
   .enter-fund{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #7F7F7F);
   }
   .trans-charge{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    color: var(--DIM, #24562B);
   }
   .add-withd-panel-center{
    display: flex;
    flex-direction: column;
   }
   .change-payment{
    //font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    color: #414244;
    .fa-angle-down{
      color: var(--DIM, #24562B);
      font-size: 12px;
    }
   }
   .add-withd-panel-enter-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p{
      text-align: center;
      color: #7F7F7F;
      font-size: 12px;
    }
   }
   .add-withd-panel-enter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .proceed{
    width: Hug (138px)px;
    height: Hug (36px)px;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    background: var(--Primary-Color, #24562B);
    color: #FFFFFF;
    font-weight: 450;

   }

   .go-back{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
   }
   .cancel-trans{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;  
    color: var(--DIM, #7F7F7F);
   }
   .separator{
    color: var(--Primary-Color, #24562B);
   }
   .active-c{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 15px;
    height: 100px;
    width: 100%;
  
    .icon{
      font-size: 44px;
    }
   }
   .active-c-history{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
  
    .icon2{
      font-size: 20px;
    }
   }
   .activeo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   }
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 400;
      font-size: 11px;
      font-family: Poppins;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .location{
      color: #807b7b;
      font-weight: 550;
      font-size: 11px;
      font-family: Poppins;
      margin-top: 4px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
  
      button{
        font-size: 10px;
      }
    }
    button{
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .active-cc-history{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #00000091;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 11px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
  
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 12px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc32;
    border-radius: 4px;
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 40px;
    }
   }
  
  
  @media screen and (max-width: 768px) {
    .container-fluid{
  padding: 0 0px 0 0rem;
}
    .welcome{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      padding: 0px 15px;
      text-align: left;
      color: #170e0fac;
    }
  
    .dashboard-page{
      display: flex;
      flex-direction: column;
      strong{
        margin-top: 10px;
      }
   }
   .dashboard-inner{
    margin: 0px;
    margin-top: 10px;
    padding: 0.8rem;
   }
  
   .dashboard-inner-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.6rem;
    gap: 10px;
    border-radius: 16px;
    
    border: 3px solid #F4F4F4;
    background: var(--White-Original, #FFFFFF);
   }
   .wallet-board-new{
    display: flex;
    flex-direction: column;
   
    width: 100%;
    height: auto;
    padding: 0.9rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #24562B);
    color: #fff;
  
    .avail-amount{
      display: flex;
      flex-direction: column;
      
      width: 100%;
      .topup{
        //font-family: Montserrat;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
      }
      .add-money{
        //font-family: Montserrat;
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        color: #fff;
      }
      .avail-bal{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 400;
      }
      .trans-his{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        color: #D9D9D9;
      }
  
    }
   
    .input-amount-symbol{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #7F7F7F;
  
      span{
        color: #2D2D2D;
        //font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        width: 21px;
        height: 20px;
        gap: 0px;
        
  
      }
     }
     .input-amount{
      width: 100%;
      height: 32px;
      gap: 0px;
      
      background: #FFFFFF;
     }
     .amount-p{
      color: var(--DIM, #7F7F7F);
      font-size: 10px;
     }
     .enter-fund{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #7F7F7F);
     }
     .trans-charge{
      //font-family: Montserrat;
      font-size: 8px;
      font-weight: 400;
      color: var(--DIM, #24562B);
     }
     .add-withd-panel-center{
      display: flex;
      flex-direction: column;
     }
     .change-payment{
      //font-family: Montserrat;
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      color: #414244;
      .fa-angle-down{
        color: var(--DIM, #24562B);
        font-size: 10px;
      }
     }
    .tran-history{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .balance{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
      }
     .pending-history{
      display: flex;
      flex-direction: column;
     }
      .pending{
        font-family: Montserrat;
        font-size: 8px;
        font-weight: normal;
        color: #D9D9D9;
      }
      .pending-fund{
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
      }
    }
   }
   .wallet-board-new-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border-radius: 13px;
    
  
    .avail-amount-c{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
    }
    .amount-cc{
        font-size: 10px;
        font-weight: 500;
        color: #181818;
    }
    .trans-expired{
        font-size: 11px;
        font-weight: 300;
        color: #181818;
    }
    .trans-time{
        //font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        color: #F22323;
    }
    .trans-amount-send{
        //font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #414244;
    }
    .trans-amount-charge{
       // font-family: Montserrat;
        font-size: 30px;
        font-weight: 500;
        color: #24562B;
    }
   }
   .wallet-board-new-t{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.2rem;
    border-radius: 13px;
    
    background: var(--Primary-Color, #24562B);
    color: #fff;
   }
   .account-number-text{
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .account-number{
    font-size: 20px;
    font-weight: 501;
    color: var(--DIM, #7F7F7F);
    margin-left: -0.5rem;
   }
   .copy-number{
    display: none;
   }
   .copy-number1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 25px;
    border-radius: 72px;
    padding: 0rem;
    background: var(--Primary-Color, #24562B);
   
    .fa-copy{
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
    }
   }
   .copy-number-t {
    height: 20px;
   }
   .bank-name-text{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .account-name-text{
    //font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #181818;
   }
   .bank-name{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .account-name{
    //font-family: Montserrat;
    font-size: 11px;
    font-weight: 503;
    color: var(--DIM, #7F7F7F);
   }
   .reference-text{
    font-size: 10px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
   }
   .reference{
    font-size: 10px;
    font-weight: 500;
    color: var(--DIM, #252323);
    text-align: center;
   }
   .proceed{
    font-size: 10px;
    font-weight: 450;
   }
   .pls-remember{
    font-size: 12px;
    font-weight: 500;
    color: var(--DIM, #7F7F7F);
    ;
   }
   ol li p{
    font-size: 10px;
    font-weight: 400;
    text-align: justify;
    color: var(--DIM, #7F7F7F);
   }
   .add-withd-panel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .add-fund{
    width: Fixed (111.7px)px;
    height: Fixed (38px)px;
    padding: 12.67px 16.12px 12.67px 16.12px;
    border-radius: 81.53px;
    
    background: var(--Primary-Color, #24562B);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 4px;
  
   }
   .withd-fund{
    width: Fixed (112.59px)px;
    height: Hug (38px)px;
    padding: 12.67px 9.85px 12.67px 9.85px;
    border-radius: 81.53px;
    
    background: var(--Secondary-color, #08294A);
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.79px;
    text-align: left;
    color: #FFFFFF;
  
   }
  
   .active-c{
  
    .icon{
      font-size: 40px;
    }
   }
  
   .active-cc{
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    font-family: Poppins;
  
    .name1{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .name{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .location{
      color: #807b7b;
      font-weight: 200;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .amount{
      color: #2979FF;
      font-weight: 500;
      font-family: Poppins;
      font-size: 10px;
    }
  
    .cost-price{
      color: #807b7b;
      font-weight: 600;
      font-family: Poppins;
      margin-top: 10px;
      font-size: 10px;
    }
  
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
    }
  
   }
   .wallet-board{
    padding: 0.5rem;
  }
  
  .account-note{
    border-bottom: solid 1px #ccc;
    padding: 0.4rem;
  
      p{
        font-family: Poppins;
        font-weight: normal;
        font-size: 11px;
        line-height: 1;
      }
  
   }
   .account-number{
      padding: 0.4rem;
    
    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #ccc;
      span{
        color: #fff;
        font-size: 11px;
      }
    }
   }
   .wallet-balance{
      h4{
        font-weight: normal;
        font-size: 14px;
        color: #000;
        text-shadow: 1px 0px 1px #ccc;
      }
   }
   .withdrawal{
      font-family: Poppins;
      font-weight: normal;
      font-size: 10px;
      color: #2979FF;
      border: solid 1px;
      padding: 0.2rem;
   }
  
   .active-c-history{
    padding: 0px;
    margin-top: 10px;
    .icon2{
      font-size: 15px;
    }
   }
  
   .active-cc-history{
    padding: 0.2rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      
      span{
        color: #00000091;
        font-style: italic;
      }
    }
    .amount{
      color: #2979FF;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .amount-w{
      color: #ff2929;
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
    }
    .date-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 8px;
    }
  
    .name-w{
      font-weight: normal;
      font-family: Poppins;
      font-size: 9px;
      span{
        color: #2979FF;
        font-style: italic;
      }
    }
  
   }
   .active-cc-user{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Poppins;
  
    .name{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #2979FF;
      font-weight: 700;
    }
    .email{
      font-weight: normal;
      font-family: Poppins;
      font-size: 10px;
      color: #807b7b;
    }
  
   }
   .active-c-user{
    padding: 0px;
    margin-top: 4px;
    .icon3{
      font-size: 23px;
    }
   }
   .account-content {
    margin: 0px;
    margin-right: 0px;
  }
    
  }
  
  </style>