<template>
    <div class="container-fluid">
        <br>
        <p class="text-header">DISPUTE</p>
        <div class="account-details" v-if="addDispute">
            <div class="col-sm-12">
                <div class="contract-header">
                    <div>CREATE DISPUTE </div>
                    <div> <i class="fa fa-eye" @click="view_disputes" style="cursor:pointer;"> List Disputes</i></div>
                </div>
                <form @submit.prevent="createDispute" enctype="multipart/form-data">
    
                    <div class="inputs price-fee">
                        <v-autocomplete required v-model="dispute_subject" label="Select dispute *" :items="disputeSubject" variant="outlined"></v-autocomplete>
                        <v-autocomplete required v-model="contract_list" label="Select service *" :items="contractList" variant="outlined"></v-autocomplete>
                        <v-autocomplete required v-model="dispute_priority" label="Priority *" :items="priorityType" variant="outlined"></v-autocomplete>
                    </div>
                    <div class="inputs">
                        <label for=""> Description *</label>
                        <quill-editor class="quill-container" v-model="dispute_description" :options="editorOptions"></quill-editor>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                                      
                    <div class="image-input dmargin-top">
                        <div class="inputs">
                            <label for=""><i class="fa fa-image"></i> Media upload </label>
                            <input type="file" ref="fileInput" id="image_input" class="file-input" accept="image/*" multiple  @change="pickFile" />
                        </div>
                    </div>    
                </form>
    
                <div class="inputs">
                    <button id="submit2" class="btn btn-danger btn-block" :disabled="isDisputeFieldsEnabled" @click="createDispute()" v-if="!loading">create ticket</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
        <div class="account-details" v-if="viewDispute">
            <div class="col-sm-12 table table-responsive">
                <div class="contract-header">
                    <div>My Disputes <span class="badge badge-primary">{{ disputeQty }}</span></div>
                    <div> <i class="fa fa-plus-square" @click="add_products" style="cursor:pointer;font-weight: bold"> Create New Dispute</i></div>
                </div>
    
                <div class="table table-responsive table-sm">
                    <v-data-table :headers="header" :items="desserts" dense class="table-striped table-bordered table-responsive">
                        <template v-slot:item.ticketid="{ item }">
                            <div class="a-text">{{ item.ticketid }}</div>
                        </template>
                        <template v-slot:item.disputetype="{ item }">
                            <div class="a-text">{{ item.disputetype }}</div>
                        </template>
                        <template v-slot:item.contract="{ item }">
                            <div class="a-text">{{ item.contract }}</div>
                        </template>
                        <template v-slot:item.description="{ item }">
                            <div class="a-text" v-html="item.description"></div>
                        </template>
                        <template v-slot:item.priority="{ item }">
                            <div class="a-text text-success" v-if="item.priority == 'High'">{{ item.priority }}</div>
                            <div class="a-text text-info" v-else-if="item.priority == 'Medium'">{{ item.priority }}</div>
                            <div class="a-text text-primary" v-else-if="item.priority == 'Low'">{{ item.priority }}</div>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <div class="a-text text-danger" v-if="item.status == 1">Open</div>
                            <div class="a-text text-primary" v-else-if="item.status == 2">In-Progress</div>
                            <div class="a-text text-success" v-else-if="item.status == 3">Resolved</div>
                        </template>
                                                
                    </v-data-table>
                </div>
            </div>
        </div>

        <!-- <div v-if="isContractLink">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-0">
                            </div>
                            <div class="col-md-12">
                                <div class="row account-content">
                                    <div class="account-content-d">
                                        <div class="inputs ">
                                            <center>
                                            <p style="font-size: 13px"><strong>Escrow successfully created</strong></p>
                                            <span>Escrow link generated! Please copy and share with the other party</span>

                                            <div class="a-text1">
                                            <button class="btn btn-default copy-refid1" data-toggle="tooltip" data-placement="top" title="" @click="copyToClipboard1(contractLink)">
                                                <i class="fa fa-copy"></i> Copy Link
                                            </button>
                                            
                                            </div>
                                            </center>
                                        </div>
                                    </div>
                                    <button id="submit2" class="btn btn-default font-weight-bold" @click="copied()">X</button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-0">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div> -->
    
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
      
    <script>
    import 'quill/dist/quill.snow.css';
    import {
        quillEditor
    } from 'vue-quill-editor';
    
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
    
        name: "ProductPage",
        components: {
            quillEditor,
            PageLoader
        },
    
        data() {
            return {
    
                isLoading: false,
                isRightImage: false,
                isLeftImage: false,
                isBackImage: false,
                is_transaction_fee: true,
                is_transaction_fee_hidden: false,
                user_id: '',
                firstname: '',
                lastname: '',
                bank_name: '',
                account_name: '',
                account_number: '',
    
                loading: false,
                loading1: false,
                loading2: false,
                loading3: false,

                selectedFile: '',
                addDispute: false,
                viewDispute: true,
                editorOptions: {
                    placeholder: 'Write something...',
                },

                img_url: process.env.VUE_APP_IMAGE_URL,
                desserts: [],
                header: [
                   
                    {
                        value: 'ticketid',
                        text: '#Ticket number'
                    },
                    {
                        value: 'disputetype',
                        text: 'Dispute topic'
                    },
                    {
                        value: 'contract',
                        text: 'Service'
                    },
                                       
                    {
                        value: 'description',
                        text: 'Description'
                    },
                    {
                        value: 'priority',
                        text: 'Priority'
                    },
                    {
                        value: 'status',
                        text: 'Status'
                    },
                ],
                dispute_subject: '',
                disputeSubject: [],
                contract_list:'',
                contractList: [
                    {
                    text: 'Data',
                    value: 'Data'
                },
                {
                    text: 'Airtime',
                    value: 'Airtime'
                },
                {
                    text: 'Electricity',
                    value: 'Electricity'
                },
                {
                    text: 'Cable TV',
                    value: 'CableTV'
                },
                ],
                dispute_priority: '',
                priorityType: [{
                    text: 'High',
                    value: 'High'
                },
                {
                    text: 'Medium',
                    value: 'Medium'
                },
                {
                    text: 'Low',
                    value: 'Low'
                },
            ],
                dispute_description: '',
                disputeQty: '',

            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
    
            this.bank_name = localStorage.getItem("bank_name");
            this.account_name = localStorage.getItem("account_name");
            this.account_number = localStorage.getItem("account_number");
            this.user_role = localStorage.getItem("user_role");
    
            //this.fetchPaidContractByUser()
            this.fetch_dispute_subject()
            this.fetch_disputes()
        },
    
        created() {
    
        },
    
        computed: {
    
            isDisputeFieldsEnabled() {
                return (
                    this.dispute_subject === '' ||
                    this.contract_list === '' ||
                    this.dispute_priority === '' ||
                    this.dispute_description === ''
                );
            },
    
            indexSn() {
                return this.desserts.map((d, index) => ({
                    ...d,
                    sn: index + 1
                }))
            },
    
        },
    
        methods: {
                
            async fetch_dispute_subject() {
                try {
    
                    const response = await this.$http.get('/fetch-dispute-subject', {})
                    response.data.data.forEach(el => {
                        this.disputeSubject.push({
                            value: el.subjectid,
                            text: el.subjects,
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },

            async createDispute() {
                try {
    
                    if (!this.dispute_subject) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            width: 450,
                            height: 5,
                            text: 'Please select dispute',
                        })
                        this.loading = false
                        return;
                    }
                    if (!this.contract_list) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            width: 450,
                            height: 5,
                            text: 'Please select service',
                        })
                        this.loading = false
                        return;
                    }
                    if (!this.dispute_priority) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            width: 450,
                            height: 5,
                            text: 'Please select priority',
                        })
                        this.loading = false
                        return;
                    }

                    if (!this.dispute_description) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            width: 450,
                            height: 5,
                            text: 'Please describe your problem',
                        })
                        this.loading = false
                        return;
                    }

                    // if (!this.selectedFile) {
                    //     swal.fire({
                    //         icon: 'warning',
                    //         title: 'Warning',
                    //         width: 450,
                    //         height: 5,
                    //         text: 'Please attach contract document',
                    //     })
                    //     this.loading = false
                    //     return;
                    // }
                                      
                    this.loading = true
    
                    const formData = new FormData();
    
                    //Loop through each selected file and append it to the formData
                    for (let i = 0; i < this.selectedFile.length; i++) {
                        formData.append('images', this.selectedFile[i]);
                    }
    
                    formData.append('dispute_subject', this.dispute_subject),
                    formData.append('contract_list', this.contract_list),
                    formData.append('dispute_priority', this.dispute_priority),
                    formData.append('dispute_description', this.dispute_description),
                    formData.append('user_id', this.user_id),
    
                    this.response = await this.$http.post('/create-dispute', formData
    
                    );
                    this.loading = false
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        return;
                    } else {
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                       
                        this.addDispute = false,
                        this.viewDispute =true,
                                               
                        this.dispute_subject = ''
                        this.contract_list = ''
                        this.dispute_priority = ''
                        this.dispute_description = ''
                        this.fetch_disputes()
                        return;
                    }
                } catch (err) {
                    console.log(err);
                }
            },
           
            copyToClipboard(link) {
               // var copyText = document.getElementById("myInput");
                navigator.clipboard.writeText(link).then(function() {
                    alert("Contract link copied to clipboard!");
                }).catch(function(err) {
                    console.error('Failed to copy: ', err);
                });
            },
    
            copyToClipboard1(link) {
               // var copyText = document.getElementById("myInput");
                navigator.clipboard.writeText(link).then(function() {
                    alert("Contract link copied to clipboard!");
                }).catch(function(err) {
                    console.error('Failed to copy: ', err);
                });
            },

            async fetchPaidContractByUser() {
                try {
                    this.isLoading = true
                    this.desserts = []
                    const response = await this.$http.post('/fetch-paid-contracts', {
                        user_id: this.user_id
                    })
                    this.isLoading = false
                
                    response.data.data.forEach(el => {
                        this.contractList.push({
                        value: el.contractid,
                        text: el.contract_title,
                        })
                    });

                } catch (err) {
                    console.log(err);
                }
            },

            async fetch_disputes() {
                try {
    
                    this.desserts = []
                    const response = await this.$http.post('/fetch-dispute-byuser', {
                        user_id: this.user_id
                    })
                    const dispute_list = response.data.data
                    this.disputeQty = dispute_list.length
                    dispute_list.forEach(el => {
                        this.desserts.push({
                            ticketid: el.ticket_id,
                            disputetype: el.subjects,
                            contract: el.contract_title,
                            description: el.content,
                            priority: el.priority,
                            status: el.dispute_status,
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
        
            pickFile() {
                this.selectedFile = this.$refs.fileInput.files;
            },
           
            view_disputes() {
                this.viewDispute = true,
                this.addDispute = false
            },
            add_products() {
                this.viewDispute = false,
                this.addDispute = true
            }
    
        }
    
    }
    </script>
      
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    .container-fluid{
    background: #cccccc41;
    border-radius: 10px;
    padding: 0 40px 0 15rem;
    min-height: 100vh;
    }
    .error {
        color: #fff;
      }
      .error1 {
        color: #fff;
      }
    .quill-container {
        height: 200px;
        /* Set the desired height here */
    }
    .copy-refid{
        cursor: pointer;
        font-size: 16px;
    }
    .copy-refid1{
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }
    .a-text {
        font-size: 11px;
        font-family: Poppins;
    }
    .a-text1 {
        font-size: 14px;
        font-family: Poppins;
    }
    .process-payment{
        cursor:pointer;
    
    }
    label {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
    }
    
    .inputs {
        margin-top: 20px;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .price-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        div {
            margin: 4px;
            width: 100%;
    
        }
    }
    
    .adjust-fee-input {
        // width: 100px;
        text-align: center;
    }
    
    .whopays-fee-input {
        //width: 100% !important;
    }
    
    .shop-detail {
        font-size: 12px;
        font-family: Poppins;
    }
    
    .btn-danger {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Poppins;
        background: #24562B;
        border: 0px;
        height: 45px;
    }
    
    .text-header {
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        background: #ccc;
    }
    
    .contract-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #24562B;
        color: #fff;
        border-radius: 4px;
        padding: 0.8rem;
        font-size: 12px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 5px;
    }
    
    .welcome {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        color: #170e0fac;
    }
    
    .account-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        //padding: 0.9rem;
        background: #fff;
        width: 98%;
    }
    
    .account-balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #780712;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .wallet-detail {
        padding-top: 1rem;
        line-height: 1.4px;
        font-size: 15px;
    
        h4 {
            font-weight: 650;
        }
    
        .acct-d {
            line-height: 0.8;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    
    .wallet-icon {
        font-size: 60px
    }
    
    .fund-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #076378;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .inputs-field {
        font-size: 14px;
        font-family: Poppins;
        height: 45px;
    }
    
    .content-center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 1px #170e0fac;
        border-radius: 4px;
        padding: 0.5rem;
        margin: 4px;
    }
    
    .box1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .image-input {
        margin-top: 10px;
    }
    
    .box-icon {
        padding-left: 0.4rem;
        font-size: 35px;
        color: #637807;
    }
    
    .box-details {
        font-size: 14px;
        color: #170e0fac;
    }
    
    .box1:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box3:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box4:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box5:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box6:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    
    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;
    
        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 10px;
            text-transform: capitalize;
            color: #ff4d63;
        }
    
        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }
    
    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        color: rgb(237, 138, 108);
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 90%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .account-content-h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
    
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        //text-decoration: underline;
    }
    
    .add-fund {
        font-size: 12px;
        text-decoration: none !important;
        text-transform: capitalize;
    }
    
    .account-content-d {
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .btn-secondary {
        text-transform: uppercase;
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .shop-name {
        //font-weight: 550;
        color: #000;
        font-family: Poppins;
    }
    
    @media screen and (max-width: 768px) {
        .container-fluid{
        padding: 0 0px 0 0rem;
    }
        .copy-refid1{
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
        .inputs-field {
            font-size: 12px;
            font-family: Poppins;
            font-weight: normal;
            height: 45px;
        }
        .contract-header {
    
            font-size: 12px !important;
    
        }
        
    
        .counter {
            font-size: 13px;
            font-weight: 500 !important;
        }
    
        .welcome {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #170e0fac;
        }
    
        .content-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.9rem;
        }
    
        .image-input {
            margin-top: 35%;
        }
    
        .price-fee {
            display: flex;
            flex-direction: column;
    
            div {
                width: 100%;
            }
        }
        .margin-mobile{
            margin-top: 90px;;
        }
    
        .adjust-fee-input {
            width: 100%;
        }
    
        .account-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 100%;
        }
    
        .account-balance {
            margin-bottom: 10px;
        }
    
        .box-details {
            padding: 0.4rem;
            line-height: 1.4px;
    
            .title {
                font-family: Poppins;
                font-weight: 550;
                font-size: 8px;
                text-transform: capitalize;
                color: #ff4d63;
            }
    
            .description {
                font-family: Poppins;
                font-size: 12px;
            }
        }
    
        .inputs {
            font-size: 12px;
        }
    
        .box1 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box2 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box3 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box4 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box5 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .continue {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding-right: 0.4rem;
            font-size: 30px;
            margin-top: -10px;
            color: rgb(237, 138, 108);
        }
    
        .modal-close-mobile {
            display: block;
        }
    
        .modal-close {
            display: none;
        }
    
        .account-content {
            margin: 0px;
            margin-right: 0px;
        }
    
    }
    </style>
    