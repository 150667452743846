<template>
<div id="top" style="background: #fff">
        <div class="fixed-top">
            <div id="top-header" class="top-header">
                <div class="haddress">
                <span><i class="fa fa-map-marker" aria-hidden="true"></i> 21 Ibezim Obiajulu Street off Adelabu, Surulere, Lagos</span>
                <span><i class="fa fa-phone"></i> +2348172101336</span>
                <span><i class="fa fa-envelope"></i> <a href="mailto:info@plepower.ng">info@plepower.com.ng</a> </span>
                </div>
                <div class="htime" >
                    <span></span>
                    <span><i class="fa fa-clock" aria-hidden="true"></i> Office Hours: 08:00am - 05:00pm</span>
                    <span></span>
                </div>
                <div class="hsocial">
                <span><i class="fa fa-headphones" aria-hidden="true"></i> 24hr support</span>
                
                </div>
            </div>
            <div class="col-12 d-flex flex-row justify-space-between align-center" style="background: #012B20;;">
                <div class="col-0"></div>
                <div class="col-2 logo">
                    <router-link to="/"> <img src="logo.png" alt="Logo"> </router-link>
                </div>
                <div class="col-8 col-lg-6 d-flex flex-row justify-space-around align-center menu-bar">
                    <div class="menu">
                        <a href="#home">Home</a> 
                    </div>
                    <div class="menu">
                        <a href="#core-values">Core Values</a> 
                    </div>
                    <div class="menu">
                        <a href="#aboutus">About Us</a> 
                    </div>
                    <div class="menu">
                        <a href="#services">Services</a> 
                    </div>
                   
                    <!-- <div class="menu">
                        <a href="#faq">FAQ</a> 
                    </div> -->
                    <div class="menu">
                        <a href="#contact">Contact Us</a> 
                    </div>
                </div>
                <div class="col-2 col-lg-2">
                    <div class="menu-bar1">
                        <span><i class="fa fa-bars" aria-hidden="true"></i></span>
                        <Transition>
                            <div class="submenu-bar1">
                                <a href="#home" class="sub-menu1">Home</a> 
                                <a href="#core-values" class="sub-menu1">Core Values</a> 
                                <a href="#aboutus" class="sub-menu1">About Us</a>
                                <a href="#services" class="sub-menu1">Services</a> 
                                
                                <!-- <a href="#faq" class="sub-menu1">FAQ</a>   -->
                                <a href="#contactus" class="sub-menu1" >Contact Us</a>  
                            </div>
                        </Transition>
                    </div>
                </div>
                <div class="col-0"></div>
            </div>
        </div>
        <transition name="slide">
            <div
              id="home"
              class="col-lg-12 col-sm-12 banner"
            >
              <div class="overlay"></div>
              <div class="col-0"></div>
              <div class="col-lg-8 col-sm-8 btn-style">
                <h1>Welcome to<br>PlePower</h1>
                <h4>
                  At PlePower, we provide platform for Electricity Payment, the most affordable Data Bundles, Airtime,
                  <br />
                  DStv, GOtv Subscriptions services.
                </h4>
                <button
                  id="core-values"
                  class="btn btn-success mt-4"
                  @click="get_started"
                >
                  Get Started
                </button>
              </div>
              <div class="col-lg-4 col-sm-4 img-style">
                <img src="../assets/bg.png" alt="" />
              </div>
            </div>
          </transition>
        <div  class="pa-5 core-values">
            <div class=" guarantee">      
                <h3><i class="fa fa-wrench size-all"></i> <br>Automated Services </h3> 
                <span>We use cutting-edge technology to run our services </span>
            </div>
            <div class=" guarantee">      
                <h3><i class="fa fa-check size-all"></i> <br>Quality Services </h3> 
                <span>We are committed to delivering exceptional quality services <br>that prioritize reliability, efficiency, and customer satisfaction</span>
            </div> 
            <div class=" guarantee">
                <h3><i class="fa fa-desktop size-all"></i>  <br> Reliable </h3>
                <span> PlePower is designed for maximum reliability and dependability, <br>ensuring 100% satisfaction with every transaction.</span>
            </div> 
            <div class="guarantee"> 
                <h3> <i class="fa fa-lock size-all"></i>  <br>Secured Payment</h3>
                <span>All payments are processed on a verified, secure, <br>and reliable fintech infrastructure</span>
            </div>
        </div>
        <div id="aboutus" class="col-lg-12 about">
            <div class="overlay2"></div>
            <div class="col-lg-7 col-sm-7">
                <h2>About PlePower?</h2>
                <p></p>
                <p>PlePower is Nigeria's leading Value Added Solution (VAS) organization that offers instant Electricity Bill Payment, recharge of Airtime, 
                    Data Bundle, Cable TV to our customers.
                </p>
            </div>
            <div class="col-lg-5 col-sm-5 d-flex flex-column">
                 <h4 class="try-us">Try Us Today</h4>
                 <p>Create account today or login to enjoy our services</p>
                 <div class="btn-account">
                    <button @click="sign_in" class="btn btn-success sign-in"><i class="fa fa-sign-in"></i> Sign In</button>
                    <button @click="sign_up" class="btn btn-success sign-up"><i class="fa fa-user"></i> Sign Up</button>
                 </div>
                 
            </div>
         </div>
        <div id="services" class="col-lg-12 pt-3 bg-style">
            <div class="overlay1"></div>
            <div class="col-lg-4 col-sm-4 pt-4 pa-2">
                <img src="../assets/1.png" alt="">
            </div>
            <div class="col-lg-5 col-sm-5 platform-1">
                <div class="platform">
                    <h2>Our Services</h2>
                    <p>All Our Sevices Are Automated With Insatnt Delivery.</p>
                    <span class="select-item">
                        <i class="fas fa-lightbulb"></i> Electricity Payment
                    </span>
                    <span class="select-item">
                        <i class="fas fa-mobile-alt"></i> Airtime Topup
                    </span>
                    <span class="select-item">
                        <i class="fas fa-wifi"></i> Buy Data
                    </span>
                    <span class="select-item">
                        <i class="fas fa-satellite-dish icon"></i> DStv Subscription
                    </span>
                    <span class="select-item">
                        <i class="fas fa-satellite-dish icon"></i> GOtv Subscription
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 pt-4  mobile-img d-flex flex-column">
                <img src="../assets/mobile.jpg" alt="">
                <div class="d-flex flex-column align-items-start mobile-store">
                    <p class="pt-2">Download our mobile App to enjoy seamless services</p>
                     <img src="../assets/google-play.png" alt="" class="google-play">
                </div>
            </div>
        </div>        
       
        <!-- <div id="faq" class="col-lg-12 col-sm-12 bg-task">
            <div class="col-lg-1 col-sm-1">
            </div>
            <div class="col-lg-8 col-sm-8">
                <div class="d-flex flex-column pt-0 mt-0">
                    <div id="accordion">
                        <div class="card">
                          <div class="card-header">
                            <a class="card-link" data-toggle="collapse" href="#collapseOne">
                             <div><span class="badge badge-secondary">+</span>&nbsp;How do I start?</div>
                            </a>
                          </div>
                          <div id="collapseOne" class="collapse show" data-parent="#accordion">
                            <div class="card-body">
                               <ul>
                                <li>Browse through our selection of items.</li>
                                <li>Add your desired items to your cart.</li>
                                <li>Select your preferred installment period.</li>
                                <li>Choose the duration of your installments.</li>
                                <li>Make your payment.</li>
                               </ul> 
                            </div>
                          </div>
                        </div>
                      
                        <div class="card">
                          <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                <div><span class="badge badge-secondary">+</span>&nbsp;How do I make payment?</div>
                            </a>
                          </div>
                          <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              You can make your payment via the following payment channels:
                              <ul>
                                <li>Bank Transfer.</li>
                                <li>USSD</li>
                                <li>ATM Card</li>
                                <li>Bank</li>
                               </ul> 
                            </div>
                          </div>
                        </div>
                      
                        <div class="card">
                          <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                <div><span class="badge badge-secondary">+</span>&nbsp;Do I need to complete payment before getting my item(s)?</div>
                            </a>
                          </div>
                          <div id="collapseThree" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                             No. Once you have made payment upto 50% of the cost, you can then request for delivery.
                            </div>
                          </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                              <a class="collapsed card-link" data-toggle="collapse" href="#collapseFour">
                                  <div><span class="badge badge-secondary">+</span>&nbsp;What If the price of the item has changed before or after I have paid upto 50%?</div>
                              </a>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#accordion">
                              <div class="card-body">
                                When the price of item has decrease, Daily Champ will credit your Champ Pocket with the excess amount. In the case the price of the item increases, Daily Champ reserve the right to spread the price difference to your current installment or not.
                              </div>
                            </div>
                          </div>

                          <div class="card">
                            <div class="card-header">
                              <a class="collapsed card-link" data-toggle="collapse" href="#collapseFive">
                                  <div><span class="badge badge-secondary">+</span>&nbsp;Can I pay outrightly instead of installment payment?</div>
                              </a>
                            </div>
                            <div id="collapseFive" class="collapse" data-parent="#accordion">
                              <div class="card-body">
                                Yes, you can pay at once by selecting pay-once.
                              </div>
                            </div>
                          </div>

                          
                      </div> 
                </div>
        
            </div>
            <div class="col-lg-3 col-sm-3">
                <i class="fa fa-question-mark"></i>
            </div>
        </div> -->
        <div id="contact" class="col-lg-12 contact">
            <div class="col-lg-4 bd">
                <h4 class="support-text">Contact Us</h4>
                <p>Our dedicated support team is always here to help you out.</p>
                <div class="d-flex flex-row justify-content-center">
                    <i class="fa fa-headphones" style="font-size: 200px; color: #ccc"></i>
                </div>
           </div>
            <div class="col-lg-4 contact-banner bd">
                <h4>Contact Form</h4>
                 <label>Fullname *</label>
                 <input class="form-control" type="text" name="" v-model="fullname" id="" placeholder="Enter your fullname">
                 <label>Email *</label>
                 <input class="form-control" type="email" name="" v-model="email_address" id="" placeholder="Enter email address">
                 <label>Phone *</label>
                 <input class="form-control" type="text" name="" v-model="phone_number" id="" placeholder="Enter phone number">
                 <label>Mesasge *</label>
                 <textarea class="form-control" name="" v-model="message" id="" cols="3" rows="4" placeholder="Tell us how we can help you"></textarea>
                 <br>
                 <button id="submit4" class="btn btn-success btn-sm input-contact" :disabled="isSubmitButtonDisabled" @click="submit_form()" v-if="!loading">Send message</button>
                 <button class="btn btn-success btn-sm input-contact" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                 </button>
                
            </div>
            <div class="col-lg-4 bd">
                 <h4><i class="fa fa-envelope" aria-hidden="true"></i> Email Us</h4>
                 <p>Our dedicated team will help you out<br><span class="text-secondary"><a href="mailto:info@plepower.com.ng" class="text-secondary">info@plepower.com.ng</a></span></p>
                 <h4><i class="fa fa-map-marker" aria-hidden="true"></i> Our Office</h4>
                 <p>Visit us today at our office headquarter<br> <span class="text-secondary">21 Ibezim Obiajulu Street off Adelabu, Surulere, Lagos</span></p>
                 <h4><i class="fa fa-headphones" aria-hidden="true"></i> Help Desk</h4>
                 <p>You can reach out via call or sms 24/7.<br> <span class="text-secondary">+2348172101336</span></p>
            </div>
            
        </div>
        <div class="content-footer" id="contactus">
            <div class="contact-info footer-img">
                <img src="logo.png" alt="">
                <p>"We guarantee fast, reliable and <br>secured transaction"</p>
            </div>
            <div class="contact-info">
                <p class="contact-title">contact info</p>
                <p><i class="fa fa-map-marker" aria-hidden="true"></i> 21 Ibezim Obiajulu Street off Adelabu, Surulere, Lagos</p>
                <p><i class="fa fa-phone"></i> +2348172101336</p>
                <p><i class="fa fa-envelope"></i> <a href="mailto:info@plepower.ng">support@plepower.com.ng</a></p>
            </div>
            <div class="contact-info">
                <p class="services-title">Useful Links</p>
                <!-- <p><a href="#" target="_blank">Vendor Hub</a> </p> -->
                <p><router-link to="#">Terms & Conditions</router-link></p>
                <p><router-link to="#">Privacy Policy</router-link></p>
                <!-- <p><router-link to="/terms-and-conditions#top">Terms & Conditions</router-link></p>
                <p><router-link to="/privacy-policy#top">Privacy Policy</router-link></p> -->
            </div>
            <div class="contact-info">
                <p class="services-title">Socials</p>
                <p> <span><a href="#" target="_blank"><i class="fa fa-facebook-square"></i> Facebook</a></span> </p>
                <p> <span><i class="fa fa-twitter-square"></i> Twitter </span></p>
                <p> <span><i class="fa fa-instagram"></i> Instagram</span></p>
            </div>
        </div>
        <div class="content-footer">
            <p class="copyright">&copy;{{ new Date().getFullYear() }} PlePower. All rights reserved. </p>
        </div>
        <!-- <div class="contact-support">
          <a href="https://wa.me/2347069500520" target="_blank"><img src="../assets/support.png" alt=""></a> 
        </div>   -->
        <a href="#top">  
        <div class="top">
            <i class="fa fa-arrow-up"></i>
        </div>  
        </a>   
</div>
</template>
<script>
import swal from 'sweetalert2';
import $ from 'jquery';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        loading: false,
        fullname: '',
        email_address: '',
        phone_number: '',
        message: '',
        show: false,
        title: 'PlePower |  Electricity, Buy Airtime, Internet Data and Cable TV',
        showImage: 1,
        exists: false,
        showBanner: true, 
        items: [
          {
            src: process.env.VUE_APP_API_BANNER,
          },
          {
            src: process.env.VUE_APP_API_BANNER2,
          },       
        ],
        showTop: true,
        lastScrollTop: 0
    };
  },

  mounted() {

    $(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.top').fadeIn();
           document.getElementById('top-header').style.display = 'none'
          
        } else {
            $('.top').fadeOut();
            if ($(this).scrollTop() == 0) {
                document.getElementById('top-header').style.display = 'flex'
            }
        }
       
    });

    $('.top').click(function() {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
        });
    });

    setInterval(() => {
        this.showImage = this.showImage === 2 ? 1 : this.showImage + 1;
    }, 10000); // Toggle images every 3 seconds

    document.title = this.title
   
  },
  beforeDestroy() {
   
  },
    created(){

    },
    computed: {
    isSubmitButtonDisabled() {
        return (
        this.fullname === '' ||
        this.email_address === '' ||
        this.phone_number === '' ||
        this.message === '' 
        );
    }
  },

  methods: {
    
    get_started(){
        this.$router.push('/register')
    },
    sign_in(){
        this.$router.push('/login')
    },
    sign_up(){
        this.$router.push('/register')
    },
    async submit_form() {
            try {

                this.loading = true

                const response = await this.$http.post('/send-message', {
                  fullname: this.fullname,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  message: this.message
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

                this.fullname = ''
                this.email_address = ''
                this.phone_number = ''
                this.message = ''

                return;
               
            } catch (err) {
              console.log(err);
        }
    },
  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

/* Define slide-in and slide-out animations */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
 .v-enter-from {
    opacity: 0;
    translate: -100px 0;
  }
  .v-enter-to {
    opacity: 1;
    translate: 0 0;
  }
  .v-leave-from {
    opacity: 1;
    translate: 0 0;
  }
  .v-leave-to {
    opacity: 0;
    translate: 100px 0;
  }
 
.top-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    padding-left: 5%;
    padding-right: 5%;
    color: #012B20;
    background: #fff;
    box-shadow: 0 2px 4px -3px rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 13px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;

    span {
        a {
            text-decoration: none;
            color: #012B20;
        }
    }
}
.htime{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
}
.hsocial{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
        margin: 10px;
    }

    a{
        color: #000;
    }
}
.core-values{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #cccccc6b;

    h3{
        font-family: Mulish;
        font-size: 20px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 200;
        text-align: center;
    }
}
.guarantee{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 10px #cccccc6b;
    border-radius: 10px;
    height: 250px;
    background: #fff;
    padding: 0 5px 0 5px;
}
.btn-success{
    border: solid 0;
    height: 50px;
}
.btn-success:hover{
    background: #000;
    border: solid 0;
    color: #FFF;
    height: 50px;
}
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.1s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #F2F2F2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    padding-left: 5%;
    padding-right: 5%;
}
.sidebar{
    display: none;
}
.fa-bars {
    display: none;
}
.menu-bar {
    height:70px;
}

.menu-bar1{
    display:none;
 }
 .submenu-bar1{
    display:none;

    .submenu-bar{
        display: flex;
        flex-direction: column;
        left: 0;
        font-family: Mulish;
        font-size: 13px;
        border-right: solid 1px rgba(0, 0, 0, 0.3);;
        bottom: 0;
        height: 100vh;
    
        a{
            text-decoration: none;
            color: #012B20;
        }
    }
 }

.logo{
    img{ width: 30%; }
}

.menu > a {
    color: #fff;
    font-weight: 800;
    font-family: Mulish;
}
.menu:hover {
    cursor: pointer;
}
.fixed-top{
    z-index: 9999;
    background: #fff;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //padding-top: 6.5rem;
    bottom: 0;
    font-family: Mulish;
    font-size: 18px;
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg.jpg) no-repeat fixed center;
    background-size: cover;
      
    h1{
        font-family: Mulish;
        font-weight: 700;
        font-size: 60px;
        color: #fff;
        text-align: left;
    }
    h4{
        font-family: Mulish;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        text-align: left;
       // text-shadow: 0px 1px #000; 
    }
    .btn-style{
        font-family: Mulish;
        text-align: left;

    }
    .img-style{
        padding: 8rem 0px 0px 0px;
        img{
            width: 80%;
            border: solid 1px #02352A;
            border-radius: 4px
        }
    }
}  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #012b20; /* Change the color and opacity here */
    opacity: 0.9;
  }
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(224, 233, 225, 0.956)/* Change the color and opacity here */
  }
.bg-style{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg1.jpg) no-repeat center;
    background-size: cover;

    .mobile-img{
        h2{
            font-size: 15px; 
            font-weight: bold;
            font-family: Mulish;
            color: #012B20;
        }
        p{
            font-size: 10px; 
            font-weight: 400;
            font-family: Mulish;
            color: #012B20;
        }
        img{
            width: 66%;
            border-radius: 10px;
         }
         .google-play{
            margin-top: -10px;
            text-align: left;
            width: 35%;
            cursor: pointer;
         }
    }
    
    img{
      
    }
    
    .platform{
        display:flex;
        flex-direction: column;
        padding: 2.6rem;

    h2{
        font-size: 30px; 
        font-weight: bold;
        font-family: Mulish;
        color: #012B20;
    }
    span{
        font-size: 14px; 
        font-weight: 350;
        font-family: Mulish;
    }
}
}

.card{
    border: solid 0px;
    border-bottom: solid 1px #fff;
    background: #fff;
   
}
.card-link{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 17px; 
    font-weight: 600;
    font-family: Mulish;
    height: 20px;
    color: #000;
   
}
.card-header{
    border-bottom: solid 1px #fff;
}
.card-body{
    font-size: 14px; 
    font-family: Mulish;
    color: #012B20;
}
.badge-secondary{
    background: #012B20;
}

.bg-task{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.2rem;
    height: auto;
   // box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    background-image: linear-gradient(-180deg, #012B2032, #fff);

    h2{
        font-size: 40px; 
        font-weight: 600;
        font-family: Mulish;

    }
    img{
        width: 90%;
    }
}
.select-item{
    margin: 4px;
    padding: 0.2rem;
    font-size: 25px !important; 
    font-weight: 600;
    font-family: Mulish;
    color: #012B20;
}
.slider {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 500px; 
    font-size: 45px; 
    font-weight: bold;
    text-shadow: 0px 1px #000; 
    font-family: Mulish;
    text-align: center;
  }
.sample-product-subtitle{
    font-size: 20px !important; 
    text-shadow: 0px 0px #f5f9f711; 
    text-align: center;
    width: 50%;
    color: #fff;
    font-weight: 300 !important;
  }
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px;
    font-family: Mulish;
    font-size:17px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    span{
        font-size:20px;
        color: #800080e6;
        font-weight: bold;
    }
  }
  .content-services{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff !important;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .content-items{
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:20px;
      font-weight: 700;

      .fa-handshake-o{
        font-size:50px;
        color: #800080e6;
      }
      .fa-wallet{
        font-size:50px;
        color: #800080e6;
      }
      .fa-graduation-cap{
        font-size:50px;
        color: #800080e6;
      }
      .fa-leaf{
        font-size:50px;
        color: #800080e6;
      }
    }
  
    .items-text {
      font-family: Mulish;
      font-size:14px;
      font-weight: 500;
      text-align: center;
    }
  
  }
  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    //background: #33aacb14;
  }
  .about{
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    font-family: Mulish;
    background-size: cover;
    margin-top: 0px;
    height: 250px;
    background-image: linear-gradient(-180deg, #cccccc6b, #fff);

    .try-us{
        font-family: Mulish;
        font-size: 20px;
        font-weight: bold;
        color: #012B20;
    }
   
    .btn-account{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-content: center;

        .sign-in{
            font-family: Mulish;
            font-size: 16px;
            font-weight: bold;
            border: solid 0px #ccc;
            border-radius: 50px;
            background: #659610;
            width: 120px;
            margin-bottom: 10px;
            margin-right: 5px;
        }
        .sign-up{
            font-family: Mulish;
            font-size: 16px;
            font-weight: bold;
            border: solid 0px #ccc;
            border-radius: 50px;
            background: #012B20;
            width: 120px;
        }
    }

    h2{
        font-family: Mulish;
        font-weight: bold;
        color: #012B20;
    }

    h4{
        font-family: Mulish;
        font-size: 15px;
        font-weight: bold;
        text-align: justify;
    }
    p{
        font-family: Mulish;
        font-size: 15px;
    }
  }

  .bd{
    padding: 1.5rem;
  }
 
  .contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 11px;
    background: #fff;
    color: #012B20 !important;
    background-size: cover;
    margin-top: 0px;
    height: auto;

    p{
        a{
            text-decoration: none;
            color: #ccc;
        }
    }

    .support-text{
        font-family: Mulish;
        font-size: 20px;
        font-weight: bold;
        text-align: justify;
    }
    h4{
        font-family: Mulish;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
    }
    span{
        font-family: Mulish;
        font-size: 13px;
        font-weight: 550;
        color: #3ea4c9c9;
    }
    img{
        width: 100%;
    }
  }
  .border{
    border-left: solid 0px #ccc;
    padding: 1.5rem;
  }
  .bd{
    padding: 1.5rem;
  }
  .contact-banner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-family: Mulish;
    padding: 1.6rem;

    .form-control{
        font-size: 12px;
        font-family: Mulish;
    }
    .input-contact{
        margin-top: 4px;
        color: #fff;
    }
  }


  .text-banner{
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    font-family: Mulish;
    width: 50%;
    p{
        text-align: justify;
    }

    .our-vision{
        margin: 4px;
        font-family: Mulish;
        font-size: 13px;

        .company{
            font-weight: bold;
        }
        .mission{
            font-weight: bold;
        }
        .vision{
            font-weight: bold;
        }
        .impact{
            font-weight: bold;
        }
    }
  }

  .overlay3 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 3.9;
    //background: #012B20;
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 2.7rem;
    padding-left: 5%;
    padding-right: 5%;
    color: #fff;
    background: #012B20;
  }
  .footer-img{
    display: flex;
    flex-direction: column;
    text-align: left
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:12px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #fff;
  }
  
  .contact-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img{ width:20% }

    p{
        font-family: Mulish;
        font-size:12px;    
    }
    a{
        text-decoration: none;
        color: #fff;
    }
  }
  .contact-title{
    font-size: 18px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .services-title{
    font-size: 16px !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
  }

  .copyright{
    font-family: Mulish;
    font-size:13px;
    font-weight: 400;
    text-align: left;
    padding-left: 5%;
  }
  
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #37ABC8;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
.top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    display: block;
    z-index: 9999;
    background: #012B20;
    color: #fff;
    border-radius: 45px;
    padding: 10px;
    transition: visibility 0.5s, opacity 0.5s;

    a{
        color: #fff;
    }
}
.hidden {
    visibility: hidden;
    opacity: 0;
}

.contact-support{
    position: fixed;
    bottom: 20px;
    right: 80px;
    z-index: 9999;
    background: #ccc;
    color: #fff;
    border-radius: 12px;
    transition: visibility 0.5s, opacity 0.5s;

    img{
        width: 60px;
    }
}
@media (min-width:320px) and (max-width: 720px) {

.top-header{
    font-size: 10px;
}
.haddress{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    font-size: 10px;
}
.core-values{
    display: flex;
    flex-direction: column;
    background: transparent;

    h3{
        font-family: Mulish;
        font-size: 30px;
        font-weight: 750;
        color: #00000096;
        text-align: center;
    }
    span{
        font-family: Mulish;
        font-size: 14px;
        font-weight: 200;
        text-align: center;
    }
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-bar {
    display: flex; 
    flex-direction: column; 
    height:70px;
}

.menu-bar1{
    display:block;
    .fa-bars{
        font-size: 22px;
        cursor: pointer;
    }
 }
 .menu-bar1:hover{
    
        .submenu-bar1{
            display: flex;
            flex-direction: column;
            right: 0;
            font-family: Mulish;
            font-size: 13px;
            border-right: solid 1px rgba(0, 0, 0, 0.3);;
            background: #012B20;
            position: fixed;
            z-index: 9998;
            //padding: 0px 30px 0px 30px;
            //margin-top:-40px;
            width: 100%;
            height: auto;
                                  
            a{
                text-decoration: none;
                color: #fff;
            }
        }
        .sub-menu1{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            border-left: solid 0px #ccc;
            border-bottom: solid 1px #ccc;
            text-transform: capitalize;
            cursor: pointer;
            padding: 0.8rem;
            width: 100%;
        }
 }
 .sub-menu1:hover{
    background: #f0f6f1;
    color: #012B20 !important;
 }

.guarantee{

    border: solid 1px #cccccc6b;
    margin-top: 6px;
    padding: 0 5px 0 5px;

    div{
        font-family: Mulish;
        font-size: 14px;
        font-weight: 450;
        color: #00000096;
        text-align: center;
    }
}
.card{
    margin-top: 2%;
    border: solid 0px;
    border-bottom: solid 1px #fff;
    background: #8000808f;
}

.card-link{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 14px; 
    font-weight: 450;
    font-family: Mulish;
    height: 20px;
    color: #000;
   
}
.card-header{
    border-bottom: solid 1px #fff;
}
.card-body{
    font-size: 13px; 
    font-family: Mulish;
    color: #fff;
}

.bg-task{
    display: flex;
    flex-direction: column;
    padding: 0rem;

    h2{
        font-size: 30px; 
        font-weight: 600;
        font-family: Mulish;

    }
    p{
        font-size: 12px; 
        font-weight: 400;
        font-family: Mulish;

    }
    img{
        width: 90% !important;
    }
    .btn{
        margin-bottom: 10px;
    }
    .task-img{
        display: none;
    }
}
.bg-style{
    display:flex;
    flex-direction: column;
    position: relative;
    backdrop-filter: blur(16px);
    background: url(../assets/bg1.jpg) no-repeat center;
    background-size: cover;

    .mobile-img{

        p{
            font-size: 10px; 
            font-weight: 400;
            font-family: Mulish;
            color: #012B20;
        }
        img{
            width: 100%;
            border-radius: 10px;
         }
         .google-play{
            margin-top: -10px;
            margin-bottom: 10px;
            text-align: center;
            width: 35%;
            cursor: pointer;
            display: block;
         }
    }


    img{
        width: 100%;
        display: none;
    }
    .platform{
        display:flex;
        flex-direction: column;
        width: 100%;
        padding: 0.6rem;

        .select-item{
            margin: 4px;
            padding: 0.2rem;
            font-size: 20px !important; 
            font-weight: 600;
            font-family: Mulish;
            color: #012B20;
        }

        h2{
            font-size: 30px; 
            font-weight: 700;
            font-family: Mulish;
        }
        span{
            font-size: 14px; 
            font-weight: 350;
            font-family: Mulish;
        }
    }
}

#dailytask{
    display: flex;
    flex-direction: column;  
    justify-content: center; 
    align-content: center;
    width: 100%;

    h2{
        font-family: Mulish;
        margin-top: -40px;
        z-index: 9666;
        color: #000000c0;
    }

    p{
        font-family: Mulish;
        font-size: 12px;
    }
    img{
        width: 120%;
    }
}

#dailytask .d-flex .flex-column .pt-0 .mt-0 {
    display: flex;
    flex-direction: column;
  
}
   
    .sidebar {
        width: auto;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        margin-left: 10px;

        a{
            color: #37ABC8;
        }
        .close{
            margin-left: 20px;
        }
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}

   .logo{
     img{ width: 150%; }
    }
    .fa-bars {
        display: block;
        font-size: 30px;
        cursor: pointer;
        color: #fff;
    }
    .menu {
        display: none;
    }
    .banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 9.5rem;
        bottom: 0;
        font-family: Mulish;
        font-size: 18px;
        //background:#800080e6;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg.jpg) no-repeat fixed center;
        background-size: cover;
        
        h1{
            font-family: Mulish;
            font-weight: 700;
            font-size: 40px;
            color: #fff;
            text-align: center;
            //text-shadow: 0px 1px #000; 
        }
        h4{
            font-family: Mulish;
            font-weight: 400;
            font-size: 18px;
            color: #fff;
            text-align: center;
            //text-shadow: 0px 1px #000; 
        }
        .btn-style{
            font-family: Mulish;
            text-align: center;
        }
        .img-style{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
                display: none;
            }
        }
        .img-style1{
            display: flex;
            flex-direction: row;
            justify-content: center;
            img{
                text-align: center;
                width: 250px;
            }
        }
    }
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
      }
      .content-items{
        box-sizing: border-box;
        width: 100%;
      }
      
      .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #33aacb14;
        background-size: cover;
        margin-top: 0px;
        box-shadow: -1px -1px 4px #ccc;
        position: relative;
        backdrop-filter: blur(16px);
        background-size: cover;
        padding: 2rem 0px 20rem 0px;

        .try-us{
            padding: 10rem 0px 0px 0px;
            font-family: Mulish;
            font-size: 20px;
            font-weight: bold;
            color: #012B20;
        }
        .btn-account{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
    
            .sign-in{
                font-family: Mulish;
                font-size: 16px;
                font-weight: bold;
                border: solid 0px #ccc;
                border-radius: 50px;
                background: #659610;
                width: 100%;
                margin-bottom: 10px;
                margin-right: 5px;
            }
            .sign-up{
                font-family: Mulish;
                font-size: 16px;
                font-weight: bold;
                border: solid 0px #ccc;
                border-radius: 50px;
                background: #012B20;
                width: 100%;
            }
        }
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: bold;
            text-align: justify;
        }
        p{
            font-family: Mulish;
            font-size: 13px;
        }
      }
      .team{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        
        h4{
            margin-top: 15px;
        }
    
        .name{
            font-family: Mulish;
            font-size: 13px;
            font-weight: bold;
        }
        i{
            margin-top: -15px;
        }
      }
      .border{
        border-left: solid 0px #ccc;
        padding: 1.5rem;
      }
      .bd{
        padding: 1.5rem;
      }

      .contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        bottom: 0;
        font-family: Mulish;
        font-size: 11px;
        background-size: cover;
        margin-top: 0px;
        height: auto;
    
        h4{
            font-family: Mulish;
            font-size: 17px;
            font-weight: 500;
            text-align: justify;
        }
        span{
            font-family: Mulish;
            font-size: 13px;
            font-weight: 800;
        }
        img{
            width: 100%;
        }
      }

      .content-footer {
        display: flex;
        flex-direction: column;
        padding-top: 2.7rem;
        bottom: 0;   
        background: #012B20; 
        padding-left: 5%;
        padding-right: 5%;
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

    
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #37ABC8;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .contact-info{
        display: flex;
        flex-direction: column;
    
        img{ width:20% }
    
        p{
            font-family: Mulish;
            font-size:13px;    
        }
      }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>