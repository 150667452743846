<template>
    <div class="container-fluid">
        <br>
        <p class="text-header">LIST USERS</p>
       
        <div class="account-details">
            <div class="col-sm-12 table table-responsive">
                <div class="contract-header">
                    <div>All Users <span class="badge badge-primary">{{ contractQty }}</span></div>
                    <div> </div>
                </div>
    
                <div class="table table-responsive table-sm">
                    <v-data-table :headers="header" :items="desserts" dense class="table-striped table-bordered table-responsive">
                        <template v-slot:item.firstname="{ item }">
                            <div class="a-text">{{ item.firstname }}</div>
                        </template>
                        <template v-slot:item.lastname="{ item }">
                            <div class="a-text">{{ item.lastname }}</div>
                        </template>
                       
                        <template v-slot:item.email="{ item }">
                            <div class="a-text">{{ item.email }}</div>
                        </template>
                        <template v-slot:item.phone="{ item }">
                            <div class="a-text">{{ item.phone }}</div>
                        </template>
                       
                        <!-- <template v-slot:item.banks="{ item }">
                            <div class="a-text"> {{item.banks}}</div>
                        </template> -->
                        <template v-slot:item.vemail="{ item }">
                            <div class="a-text"> 
                                <span class="text-success" v-if="item.vemail == 1">Verified</span>
                                <span class="text-warning" v-else>Un Verified</span>
                            </div>
                        </template>
                         <!-- <template v-slot:item.referredby="{ item }">
                            <div class="a-text"> {{item.referredby}}</div>
                        </template> -->
                        <template v-slot:item.createdat="{ item }">
                            <div class="a-text"> {{item.createdat}}</div>
                        </template>
                        
                    </v-data-table>
                </div>
            </div>
        </div>
    
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
      
    <script>
    // import 'quill/dist/quill.snow.css';
    // import {
    //     quillEditor
    // } from 'vue-quill-editor';
    
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
    
        name: "AllUsersPage",
        components: {
            //quillEditor,
            PageLoader
        },
    
        data() {
            return {
    
                isLoading: false,
                isRightImage: false,
                isLeftImage: false,
                isBackImage: false,
                is_transaction_fee: true,
                is_transaction_fee_hidden: false,
                user_id: '',
                firstname: '',
                lastname: '',
                bank_name: '',
                account_name: '',
                account_number: '',
    
                loading: false,
                loading1: false,
                loading2: false,
                loading3: false,
                role_type: '',
                contract_title: '',
                transaction_type: '',
                delivery_type: '',
                delivery_location: '',
                delivery_time: '',
                contract_amount: '',
                transaction_fee: '',
                whois_paying: '',
                contract_rebate: '',
                product_description: '',
                accept_terms: '',
    
                products: [],
                subcategorys: [],
                selectedFile: '',
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                addProduct: false,
                viewProduct: true,
                editorOptions: {
                    placeholder: 'Write something...',
                },
    
                isVariations: false,
                productid: '',
                variations: '',
                vprice: '',
                is_fees_status: false,
                is_submit: false,
                is_submit1: false,
                is_submit2: false,
                is_submit3: false,
                img_url: process.env.VUE_APP_IMAGE_URL,
                header: [
                    
                    {
                        value: 'firstname',
                        text: 'Firstname'
                    },
                    {
                        value: 'lastname',
                        text: 'lastname'
                    },
                    {
                        value: 'email',
                        text: 'Email'
                    },
                                       
                    {
                        value: 'phone',
                        text: 'Phone'
                    },
                    // {
                    //     value: 'banks',
                    //     text: 'Bank Info'
                    // },
                    {
                        value: 'vemail',
                        text: 'Verify Email'
                    },
                    // {
                    //     value: 'referredby',
                    //     text: 'Referred By'
                    // },
                    {
                        value: 'createdat',
                        text: 'Date registered'
                    },
                   
                ],
    
                desserts: [],
                user_role: '',
                product_id: '',
                userid: '',
                contractQty: '',
            
                contractsData: [],
                isPeriod: false,
                delivery_period: '',
                payment_status: '',
                is_escrow_charge: false,
                error: '',
                error1: '',
                duration_twelve: false,
                duration_twelve1: true,
                label1: '',
                isContractLink: false,
                contractLink: '',
                service_charge_fee: ''
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
    
            this.bank_name = localStorage.getItem("bank_name");
            this.account_name = localStorage.getItem("account_name");
            this.account_number = localStorage.getItem("account_number");
            this.user_role = localStorage.getItem("user_role");
    
            this.fetchAllUsers()
        },
    
        created() {
    
        },
    
        computed: {
    
            isProductEnabled() {
                return (
                    this.role_type === '' ||
                    this.contract_title === '' ||
                    this.delivery_type === '' ||
                    this.delivery_time === '' ||
                    this.contract_amount === '' ||
                    this.product_description === '' ||
                    this.whois_paying === '' ||
                    this.accept_terms === '' ||
                    this.delivery_location === ''
                );
            },
    
            indexSn() {
                return this.desserts.map((d, index) => ({
                    ...d,
                    sn: index + 1
                }))
            },
    
        },
    
        methods: {
            validateNumber() {
            const value = this.delivery_period;
            if (value < 0) {
              this.error = 'Value must be non-negative';
              this.delivery_period = ''
              return
            } else if (!Number.isInteger(Number(value))) {
              this.error = 'Value must be an integer';
              this.delivery_period = ''
              return
            } else {
              this.error = '';
            }
          },
            fee_handling(){
                if (!this.transaction_fee) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        width: 450,
                        height: 5,
                        text: 'Please provide transaction amount',
                    })
                    this.whois_paying = ''
                    return;
                }
                this.is_escrow_charge = true
                if (this.whois_paying == 'buyer') {
                        this.service_charge_fee = 'Buyer will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
                }
                else if(this.whois_paying == 'seller'){
                    this.service_charge_fee = 'Seller will pay ' + this.formatToCurrency(this.transaction_fee) + ' of the service charge'
                }
                else if(this.whois_paying == 'both'){
                    this.service_charge_fee = '50/50 (Seller pays ' + this.formatToCurrency(this.transaction_fee / 2) + ', ' + 'Buyer pays ' + this.formatToCurrency(this.transaction_fee / 2) +')'
                }
    
            },
            processPayment(contract_id){
                this.$router.push('/checkout/summary/'+contract_id)
            },
            selectDays(){
                if (this.delivery_time == '') {
                    this.isPeriod = false
                }else{
                    this.isPeriod = true
                }
            },
            select_durations(){
                if (this.delivery_type == 1) {
                    this.duration_twelve = true
                    this.duration_twelve1 = false
                    this.isPeriod = false
                    this.delivery_time = 12
                    this.label1 = '(hrs)'
                }else if(this.delivery_type == 2){
                    this.duration_twelve1 = true
                    this.duration_twelve = false
                    this.delivery_time = ''
                    this.label1 = ''
                }
            },
            viewContract(contract_id) {
                this.$router.push('/view-contract/' + contract_id)
            },
            editContract(contract_id) {
                this.$router.push('/edit-contract/' + contract_id)
            },
            async upload1(contract_id) {
                this.product_id = contract_id
                this.isRightImage = true
            },
    
            async upload2(contract_id) {
                this.product_id = contract_id
                this.isLeftImage = true
            },
    
            async upload3(contract_id) {
                this.product_id = contract_id
                this.isBackImage = true
            },
    
            async submit1() {
                try {
    
                    this.loading1 = true
    
                    const formData = new FormData();
                    formData.append('images', this.selectedFile1),
                        formData.append('product_id', this.product_id),
    
                        this.response = await this.$http.post('/upload-product1', formData
    
                        );
                    this.loading1 = false
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        return;
                    } else {
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        this.isRightImage = false
                        this.fetchMyProducts(this.user_id)
                        return;
                    }
                } catch (err) {
                    console.log(err);
                }
            },
    
            async submit2() {
                try {
    
                    this.loading2 = true
    
                    const formData = new FormData();
                    formData.append('images', this.selectedFile2),
                        formData.append('product_id', this.product_id),
    
                        this.response = await this.$http.post('/upload-product2', formData
    
                        );
                    this.loading2 = false
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
    
                        return;
                    } else {
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        this.isLeftImage = false
                        this.fetchMyProducts(this.user_id)
                        return;
                    }
                } catch (err) {
                    console.log(err);
                }
            },
    
            async submit3() {
                try {
    
                    this.loading3 = true
    
                    const formData = new FormData();
                    formData.append('images', this.selectedFile3),
                        formData.append('product_id', this.product_id),
    
                        this.response = await this.$http.post('/upload-product3', formData
    
                        );
                    this.loading3 = false
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
    
                        return;
                    } else {
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        this.isBackImage = false
                        this.fetchMyProducts(this.user_id)
                        return;
                    }
                } catch (err) {
                    console.log(err);
                }
            },
    
            addVariations(productid) {
                this.productid = productid
                this.isVariations = true
            },
    
            async fetchcategorys() {
                try {
    
                    const response = await this.$http.get('/fetch-all-categories', {})
                    response.data.categories.forEach(el => {
                        this.categorys.push({
                            text: el.category_name,
                            value: el.categoryid
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            formatToCurrency(value) {
              return value.toLocaleString('en-NG', { minimumFractionDigits: 2 })
            },
    
            async fetchContractFees(amount) {
                try {
                    
                    // Convert the input value to a number
                    const value = Number(this.contract_amount);
    
                    // Check if the value is non-negative
                    if (value < 0) {
                        this.error1 = 'Value must be non-negative';
                        this.contract_amount = '';
                        return;
                    } 
    
                    // Check if the value is an integer
                    if (!Number.isInteger(value)) {
                        this.error1 = 'Value must be an integer';
                        this.contract_amount = '';
                        return;
                    } 
    
                    // Clear the error if the input is valid
                    this.error1 = '';
    
                    // Check if the value is less than 3000
                    if (value < 3000) {
                        this.error1 = 'Transaction amount must be 3000 and above';
                        this.contract_amount = '';
                        return;
                    }
    
                    // If all checks pass, proceed with the API call
    
                    this.is_fees_status = true
                    const response = await this.$http.post('/fetch-fees', {
                        contract_amount: amount,
                    })
    
                    this.contract_amount = this.formatToCurrency(amount);
                    this.transaction_fee = this.contract_amount * (response.data.fees[0].per / 100)
                    this.contract_rebate = 0
                   
                    this.is_fees_status = false
    
                } catch (err) {
                    console.log(err);
                }
            },
    
           
            copied(){
                this.isContractLink = false
            },
            async createVariation() {
                try {
                    this.is_submit = true
                    const response = await this.$http.post('/create-variation', {
                        product_id: this.productid,
                        variation: this.variations,
                        price: this.vprice,
                        addedby: this.user_id
                    })
                    this.is_submit = false
    
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchshops() {
                try {
                    const response = await this.$http.get('/fetch-all-shops', {})
                    response.data.shops.forEach(el => {
                        this.shops.push({
                            text: el.shop_name,
                            value: el.shopid
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchbrands() {
                try {
                    const response = await this.$http.get('/get-brands', {})
                    response.data.brands.forEach(el => {
                        this.brands.push({
                            text: el.brand_name,
                            value: el.brandid
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async selectCategory() {
                try {
    
                    this.subcategorys = [] // reset list
    
                    const response = await this.$http.post('/fetch-all-subcategories-byid', {
                        category_id: this.category_name
                    })
                    response.data.categories.forEach(el => {
                        this.subcategorys.push({
                            text: el.subcategory_name,
                            value: el.subcategoryid
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            copyToClipboard(link) {
               // var copyText = document.getElementById("myInput");
                navigator.clipboard.writeText(link).then(function() {
                    alert("Contract link copied to clipboard!");
                }).catch(function(err) {
                    console.error('Failed to copy: ', err);
                });
            },
    
            copyToClipboard1(link) {
               // var copyText = document.getElementById("myInput");
                navigator.clipboard.writeText(link).then(function() {
                    alert("Contract link copied to clipboard!");
                }).catch(function(err) {
                    console.error('Failed to copy: ', err);
                });
            },
            async fetchAllUsers() {
                try {
                    this.isLoading = true
                    this.desserts = []
                    const response = await this.$http.get('/fetch-all-users', {
                        //user_id: this.user_id
                    })
                    this.isLoading = false
                    
                    this.contractsData = response.data.users
                    this.contractQty = this.contractsData.length
                    this.contractsData.forEach(el => {
                        this.desserts.push({
                            firstname: el.firstname,
                            lastname: el.lastname,
                            email: el.email_address,
                            phone: el.phone_number,
                            banks: el.bank_name +'-' + el.account_name + '-' + el.account_number,
                            vemail: el.verify_email,
                            referredby: el.referredby,
                            createdat: el.createdAt
                            
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
            async fetchMyProducts(userid) {
                try {
    
                    this.isLoading = true
                    this.desserts = []
                    const response = await this.$http.post('/fetch-product-byuserid', {
                        userid: userid
                    })
                    this.isLoading = false
                    this.products = response.data.products
                    this.contractQty = this.products.length
                    this.products.forEach(el => {
                        this.desserts.push({
                            businessname: el.shop_name,
                            brand: el.brand_name,
                            category: el.category_name,
                            subcategory: el.subcategory_name,
                            productname: el.product_name,
                            img: el.product_image,
                            price: el.product_price.toLocaleString('en-NG', {
                                minimumIntegerDigits: 2
                            }),
                            productid: el.productid,
                            product_id: el.productid,
                            p_id: el.productid,
                            right: el.right_image,
                            left: el.left_image,
                            back: el.back_image,
                        })
                    });
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async removeCategory(categoryid) {
                let con = confirm("          You're about to remove this category. Are you sure?")
                if (con == true) {
                    try {
    
                        const response = await this.$http.post('/delete-subcategory', {
                            subcategory_id: categoryid,
                        })
    
                        if (!response.data.success) {
                            swal.fire({
                                icon: 'error',
                                title: 'Error',
                                width: 450,
                                height: 5,
                                text: response.data.message,
                            })
                            return;
                        }
    
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        this.fetchProducts()
                        return;
    
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
    
            pickFile() {
                this.selectedFile = this.$refs.fileInput.files[0];
            },
            pickFile1() {
                this.selectedFile1 = this.$refs.fileInput1.files[0];
            },
            pickFile2() {
                this.selectedFile2 = this.$refs.fileInput2.files[0];
            },
            pickFile3() {
                this.selectedFile3 = this.$refs.fileInput3.files[0];
            },
            view_products() {
                this.viewProduct = true,
                    this.addProduct = false
            },
            add_products() {
                this.viewProduct = false,
                    this.addProduct = true
            }
    
        }
    
    }
    </script>
      
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    .container-fluid{
    background: #cccccc41;
    border-radius: 10px;
    padding: 0 40px 0 15rem;
    min-height: 100vh;
    }
    .error {
        color: #fff;
      }
      .error1 {
        color: #fff;
      }
    .quill-container {
        height: 200px;
        /* Set the desired height here */
    }
    .copy-refid{
        cursor: pointer;
        font-size: 16px;
    }
    .copy-refid1{
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }
    .a-text {
        font-size: 11px;
        font-family: Poppins;
    }
    .a-text1 {
        font-size: 14px;
        font-family: Poppins;
    }
    .process-payment{
        cursor:pointer;
    
    }
    label {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
    }
    
    .inputs {
        margin-top: 20px;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .price-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        div {
            margin: 4px;
            width: 100%;
    
        }
    }
    
    .adjust-fee-input {
        // width: 100px;
        text-align: center;
    }
    
    .whopays-fee-input {
        //width: 100% !important;
    }
    
    .shop-detail {
        font-size: 12px;
        font-family: Poppins;
    }
    
    .btn-danger {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Poppins;
        background: #24562B;
        border: 0px;
        height: 45px;
    }
    
    .text-header {
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        font-family: Poppins;
        background: #ccc;
    }
    
    .contract-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #24562B;
        color: #fff;
        border-radius: 4px;
        padding: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 5px;
    }
    
    .welcome {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        color: #170e0fac;
    }
    
    .account-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        //padding: 0.9rem;
        background: #fff;
        width: 98%;
    }
    
    .account-balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #780712;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .wallet-detail {
        padding-top: 1rem;
        line-height: 1.4px;
        font-size: 15px;
    
        h4 {
            font-weight: 650;
        }
    
        .acct-d {
            line-height: 0.8;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    
    .wallet-icon {
        font-size: 60px
    }
    
    .fund-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #076378;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .inputs-field {
        font-size: 14px;
        font-family: Poppins;
        height: 45px;
    }
    
    .content-center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 1px #170e0fac;
        border-radius: 4px;
        padding: 0.5rem;
        margin: 4px;
    }
    
    .box1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .image-input {
        margin-top: 10px;
    }
    
    .box-icon {
        padding-left: 0.4rem;
        font-size: 35px;
        color: #637807;
    }
    
    .box-details {
        font-size: 14px;
        color: #170e0fac;
    }
    
    .box1:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box3:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box4:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box5:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box6:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    
    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;
    
        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 10px;
            text-transform: capitalize;
            color: #ff4d63;
        }
    
        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }
    
    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        color: rgb(237, 138, 108);
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 90%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .account-content-h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
    
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        //text-decoration: underline;
    }
    
    .add-fund {
        font-size: 12px;
        text-decoration: none !important;
        text-transform: capitalize;
    }
    
    .account-content-d {
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .btn-secondary {
        text-transform: uppercase;
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .shop-name {
        //font-weight: 550;
        color: #000;
        font-family: Poppins;
    }
    
    @media screen and (max-width: 768px) {
        .container-fluid{
        padding: 0 0px 0 0rem;
    }
        .copy-refid1{
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
        .inputs-field {
            font-size: 12px;
            font-family: Poppins;
            font-weight: normal;
            height: 45px;
        }
        .contract-header {
    
            font-size: 12px !important;
    
        }
    
        .counter {
            font-size: 13px;
            font-weight: 500 !important;
        }
    
        .welcome {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #170e0fac;
        }
    
        .content-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.9rem;
        }
    
        .image-input {
            margin-top: 20px;
        }
    
        .price-fee {
            display: flex;
            flex-direction: column;
    
            div {
                width: 100%;
            }
        }
        .margin-mobile{
            margin-top: 90px;;
        }
    
        .adjust-fee-input {
            width: 100%;
        }
    
        .account-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 100%;
        }
    
        .account-balance {
            margin-bottom: 10px;
        }
    
        .box-details {
            padding: 0.4rem;
            line-height: 1.4px;
    
            .title {
                font-family: Poppins;
                font-weight: 550;
                font-size: 8px;
                text-transform: capitalize;
                color: #ff4d63;
            }
    
            .description {
                font-family: Poppins;
                font-size: 12px;
            }
        }
    
        .inputs {
            font-size: 12px;
        }
    
        .box1 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box2 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box3 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box4 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box5 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .continue {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding-right: 0.4rem;
            font-size: 30px;
            margin-top: -10px;
            color: rgb(237, 138, 108);
        }
    
        .modal-close-mobile {
            display: block;
        }
    
        .modal-close {
            display: none;
        }
    
        .account-content {
            margin: 0px;
            margin-right: 0px;
        }
    
    }
    </style>
    